import React from "react"
import { Header, HeaderLinks, Text } from "gatsby-theme-material-foundry"
import Link from "gatsby-theme-material-foundry/src/components/gatsby-link"
import { graphql, useStaticQuery } from "gatsby"
import { makeStyles, Grid, Box, Container } from "@material-ui/core"
import { FacebookIcon, TwitterIcon, EmailIcon } from "react-share"
import Dashboard from "@material-ui/icons/Dashboard"
import School from "@material-ui/icons/School"
import SocialMedia from "./SocialMedia"
import { HVLOriginal } from "./svgs"
const useSiteMetadata = () => {
  const data = useStaticQuery(
    graphql`
      query SITE_METADATA_QUERY {
        site {
          siteMetadata {
            title
            description
          }
        }
        file(relativePath: { eq: "hvl_skape_horisontal.png" }) {
          childImageSharp {
            # Specify the image processing specifications right in the query.
            # Makes it trivial to update as your page's design changes.
            fixed(width: 200) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )
  return data
}

const useStyles = makeStyles(theme => ({
  footer: {
    "& a": {
      color: "#e2e2e2",
      "&:hover": {
        color: "white",
      },
    },
  },
  button: {
    "& svg": {
      width: "300px",
      height: "100px",
    },
  },
}))

const TemplateWrapper = ({ dark, children, url }) => {
  const { file } = useSiteMetadata()
  const classes = useStyles()
  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Header
          absolute
          color={dark ? "black" : "transparent"}
          brand={<img src={file.childImageSharp.fixed.src} width="200px" alt="HVL Skape logo"/>}
          rightLinks={
            <HeaderLinks
              links={[
                {
                  dropdown: {
                    title: "arrangement",
                    icon: Dashboard,
                    links: [
                      {
                        link: "/arrangement",
                        text: "Arrangement",
                      },
                      { link: "/faste-arrangement", text: "Faste arrangement" },
                      {
                        link: "/rektors-utfordring",
                        text: "Rektors utfordring",
                      },
                    ],
                  },
                },
                { link: "/nyheter", text: "Nyheter" },
                { link: "/finansiering", text: "Finansiering" },
                {
                  dropdown: {
                    title: "Campus",
                    icon: School,
                    links: [
                      {
                        link: "/campus/bergen",
                        text: "Bergen",
                      },
                      {
                        link: "/campus/forde",
                        text: "Førde",
                      },
                      {
                        link: "/campus/sogndal",
                        text: "Sogndal",
                      },
                      {
                        link: "/campus/stord",
                        text: "Stord",
                      },
                      {
                        link: "/campus/haugesund",
                        text: "Haugesund",
                      },
                    ],
                  },
                },
                { link: "/om-oss", text: "Om Skape" },
              ]}
            />
          }
        />
        <div style={{ minHeight: "100%" }}>{children}</div>
        <div style={{ backgroundColor: "#004357" }}>
          <Container maxWidth="lg">
            <Grid container>
              <Grid item xs={12} md={2} align="center">
                <Box py={8}>
                  <HVLOriginal width="200px" fill2="#fff" />
                </Box>
              </Grid>
              <Grid item xs={12} md={6}>
                <Box className={classes.footer} py={8} mt={2} px={4} align="center">
                  <Grid container>
                    <Grid item xs={12} sm={6} md={4}>
                      <Link to="/arrangement">Arrangement</Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Link to="/nyheter">Nyheter</Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Link to="/finansiering">Finansiering</Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Link to="/om-oss">Om HVL Skape</Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Link to="/personvern">IPR & personvern</Link>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                      <Link to="/campus">Campus</Link>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box py={10} display="flex" alignContent="center" justifyContent="center">
                  <Box mx={1}>
                    <Link to="https://www.youtube.com/playlist?list=PLenkf8drY9n_h8sP7jVxubN6YVw5CtOaI">
                      <SocialMedia />
                    </Link>
                  </Box>
                  <Box mx={1}>
                    <Link to="https://www.facebook.com/hvlskape">
                      <FacebookIcon
                        size={24}
                        url="https://www.facebook.com/hvlskape"
                      />
                    </Link>
                  </Box>
                  <Box mx={1}>
                    <Link to="https://twitter.com/hvlskape">
                      <TwitterIcon size={24} />
                    </Link>
                  </Box>

                  <Box mx={1}>
                    <Link to="mailto:skape@hvl.no">
                      <EmailIcon size={24} url="mailto:skape@hvl.no" />
                    </Link>
                  </Box>
                  <Box mx={1}>
                    <Text secondary>
                      <strong>#hvlskape</strong>
                    </Text>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
    </>
  )
}

export default TemplateWrapper
