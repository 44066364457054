import React from "react"
import { Tooltip } from "@material-ui/core"

export function HVLOriginal({
  fill1 = "#00b6c9",
  fill2 = "#003146",
  width,
  height,
  className,
}) {
  return (
    <svg
      id="hvlskape"
      data-name="hvlskape"
      className={className}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 545.28 141.73"
    >
      <polygon
        fill={fill1}
        points="21.58 0 0 0 36.01 62.37 46.79 43.68 21.58 0"
      />
      <polygon
        fill={fill1}
        points="107.17 0 64.38 74.13 53.59 92.81 81.83 141.73 163.66 0 107.17 0"
      />
      <path
        fill={fill2}
        d="M308.39,151.2V133.33H291.2V151.2h-6.37V111.14h6.37v16.79h17.19V111.14h6.37V151.2Z"
        transform="translate(-83.62 -92.12)"
      />
      <path
        fill={fill2}
        d="M320.23,149.55l4.15-4.15a17.63,17.63,0,0,1-2-8.6c0-8.87,5-15,13.37-15a12.76,12.76,0,0,1,8.94,3.41l4-3.93,2.9,2.91-4.38,4.32a17.18,17.18,0,0,1,1.88,8.25c0,8.88-5.07,15-13.32,15a12.83,12.83,0,0,1-8.82-3.13l-3.87,3.76C322.1,151.43,321.14,150.52,320.23,149.55Zm8.82-8.76,11.61-11.56a6.64,6.64,0,0,0-5.07-2.1c-4.38,0-7.17,3.64-7.17,9.67A12.19,12.19,0,0,0,329.05,140.79Zm13.49-7.63-11.61,11.5a6.32,6.32,0,0,0,4.83,1.93c4.38,0,7.29-3.47,7.29-9.79A12.27,12.27,0,0,0,342.54,133.16Z"
        transform="translate(-83.62 -92.12)"
      />
      <path
        fill={fill2}
        d="M381.63,150c0,8.59-5.69,13-13.31,13-6.49,0-11.27-2.79-12.53-7.51l6-1.65c.62,2.33,3.36,4,6.49,4,4.78,0,7.11-3.24,7.11-7.79v-2.17c-1.14,2.11-4.72,3.93-8.08,3.93-7.23,0-12.69-6.09-12.69-15s5.18-15,12.69-15c3.81,0,7,2.27,8.08,4.26l.57-3.7h5.63Zm-13.31-22.94c-4.5,0-7.46,3.47-7.46,9.73s3,9.79,7.46,9.79,7.11-3.47,7.11-9.79S372.75,127.07,368.32,127.07Z"
        transform="translate(-83.62 -92.12)"
      />
      <path
        fill={fill2}
        d="M387.83,142.72c2.05-.4,4-.79,6-1.14.39,2.28,1.76,5,6.37,5,1,0,6.09-.4,6.09-4,0-6.89-17.64.23-17.64-12.12,0-4.9,4.89-8.59,11-8.59s11.38,2.56,12.23,9c-2,.34-4,.74-6,1.14-.4-3.59-2.62-4.9-6-4.9-3,0-5.07,1.59-5.07,3.36,0,2.33,2.17,3,5.58,3.3,6.49.68,12,2.11,12,8.82,0,6-5.29,9.22-12.18,9.22C392.84,151.77,388.63,147.9,387.83,142.72Z"
        transform="translate(-83.62 -92.12)"
      />
      <path
        fill={fill2}
        d="M436.44,122.4h8.36l-12.52,11.79c2.11,2.67,11.55,14.39,13.72,17h-7.35l-10.52-13-2.85,2.73v10.3h-6.2V111.14h6.2v21.91Z"
        transform="translate(-83.62 -92.12)"
      />
      <path
        fill={fill2}
        d="M455.5,138.11c0,5.92,2,8.42,6.49,8.48,4.32,0,6.37-2.79,6.37-8.48V122.4h6.15v28.8H469l-.63-3.07c-1,1.65-4,3.64-7.85,3.64-8,0-11.16-4.44-11.16-13.66V122.4h6.15Z"
        transform="translate(-83.62 -92.12)"
      />
      <path
        fill={fill2}
        d="M489.08,111.14V151.2h-6.15V111.14Z"
        transform="translate(-83.62 -92.12)"
      />
      <path
        fill={fill2}
        d="M516.68,141.19c1.87.51,3.75,1.08,5.63,1.65-1.31,4.15-5.23,8.93-13.09,8.93s-13.54-6.09-13.54-15,5.23-15,13.26-15,13,5.35,13,14a23.5,23.5,0,0,1-.11,2.73H502.05c.11,4.22,3,8,7.17,8A7.58,7.58,0,0,0,516.68,141.19ZM515.37,134c-.17-4.26-2.22-7-6.43-7s-6.43,3.42-6.6,7Z"
        transform="translate(-83.62 -92.12)"
      />
      <path
        fill={fill2}
        d="M548.43,135.55c0-5.92-2-8.48-6.49-8.48-4.32,0-6.43,2.79-6.43,8.48V151.2h-6.14V122.4h5.57l.57,3.13c1-1.76,4-3.69,7.63-3.69,8.25,0,11.38,4.49,11.38,13.71V151.2h-6.09Z"
        transform="translate(-83.62 -92.12)"
      />
      <path
        fill={fill2}
        d="M284.83,185h5.63l.57,3.64a10.59,10.59,0,0,1,8.37-4.21c7.17,0,12.29,6.14,12.29,15s-4.55,15-12.29,15a10.89,10.89,0,0,1-8.37-4v14.74h-6.2Zm13.32,4.66c-4.5,0-7.12,3.47-7.12,9.73s2.62,9.79,7.12,9.79,7.28-3.47,7.28-9.79S302.58,189.67,298.15,189.67Z"
        transform="translate(-83.62 -92.12)"
      />
      <path
        fill={fill2}
        d="M323.81,194.91c-2-.34-4-.8-6.09-1.14.63-6.37,5.86-9.33,11.9-9.33,8.13,0,12.35,4,12.35,11.83v10c0,1.71.34,2.67,1.53,2.67a5.51,5.51,0,0,0,1.48-.22v5a13.35,13.35,0,0,1-3.64.45,4.74,4.74,0,0,1-5-4.1,11.92,11.92,0,0,1-9,4.27c-6.66,0-10.64-3.7-10.64-9.16,0-4.5,4.15-8.59,11.72-8.59h7.4v-1.71c0-2.22-.91-5.24-6.2-5.24C326.09,189.67,323.93,191.44,323.81,194.91Zm12,7.74v-1.43h-6.77c-5.07,0-6.09,2.57-6.09,4,0,1.93,1.31,4,5.06,4C333,209.19,335.82,205.78,335.82,202.65ZM329.67,172a5.45,5.45,0,0,1,5.47,5.35,5.47,5.47,0,0,1-10.93,0A5.48,5.48,0,0,1,329.67,172Zm0,8a2.69,2.69,0,0,0,2.73-2.68,2.73,2.73,0,0,0-2.73-2.73,2.77,2.77,0,0,0-2.73,2.73A2.72,2.72,0,0,0,329.67,180Z"
        transform="translate(-83.62 -92.12)"
      />
      <path
        fill={fill2}
        d="M380,173.74h6.71L372.3,213.8h-6.15l-14.34-40.06h6.6l10.81,30.84Z"
        transform="translate(-83.62 -92.12)"
      />
      <path
        fill={fill2}
        d="M407,203.79c1.88.51,3.76,1.08,5.64,1.65-1.31,4.15-5.24,8.93-13.09,8.93S386,208.28,386,199.4s5.24-15,13.26-15,13,5.35,13,14.05a21.56,21.56,0,0,1-.12,2.73H392.39c.11,4.22,3,8,7.17,8A7.58,7.58,0,0,0,407,203.79Zm-1.31-7.17c-.17-4.27-2.22-7-6.43-7s-6.43,3.41-6.6,7Z"
        transform="translate(-83.62 -92.12)"
      />
      <path
        fill={fill2}
        d="M417.43,205.32c2-.4,4-.79,6-1.14.4,2.28,1.76,5,6.37,5,1,0,6.09-.4,6.09-4,0-6.88-17.64.23-17.64-12.12,0-4.89,4.89-8.59,11-8.59s11.38,2.56,12.24,9c-2.05.34-4,.74-6,1.14-.39-3.59-2.61-4.9-6-4.9-3,0-5.06,1.6-5.06,3.36,0,2.33,2.16,3,5.57,3.3,6.49.68,12,2.11,12,8.82,0,6-5.29,9.22-12.18,9.22C422.43,214.37,418.22,210.5,417.43,205.32Z"
        transform="translate(-83.62 -92.12)"
      />
      <path
        fill={fill2}
        d="M445.82,189.67V185h5V176.7h6.14V185h6v4.66h-6V206c0,2.27.68,3,2.68,3,.85,0,1.76-.11,2.73-.11v5l-3.64.23H458c-5.41,0-7.17-2.9-7.17-8.42V189.67Z"
        transform="translate(-83.62 -92.12)"
      />
      <path
        fill={fill2}
        d="M476,173.74V213.8H469.9V173.74Z"
        transform="translate(-83.62 -92.12)"
      />
      <path
        fill={fill2}
        d="M489.59,194.91c-2-.34-4-.8-6.09-1.14.62-6.37,5.86-9.33,11.89-9.33,8.14,0,12.35,4,12.35,11.83v10c0,1.71.34,2.67,1.54,2.67a5.64,5.64,0,0,0,1.48-.22v5a13.39,13.39,0,0,1-3.64.45,4.74,4.74,0,0,1-5-4.1,11.94,11.94,0,0,1-9,4.27c-6.66,0-10.65-3.7-10.65-9.16,0-4.5,4.16-8.59,11.73-8.59h7.4v-1.71c0-2.22-.92-5.24-6.21-5.24C491.86,189.67,489.7,191.44,489.59,194.91Zm12,7.74v-1.43h-6.78c-5.06,0-6.09,2.57-6.09,4,0,1.93,1.31,4,5.07,4C498.81,209.19,501.6,205.78,501.6,202.65Z"
        transform="translate(-83.62 -92.12)"
      />
      <path
        fill={fill2}
        d="M535.4,198.15c0-5.92-2-8.48-6.49-8.48-4.32,0-6.43,2.79-6.43,8.48V213.8h-6.15V185h5.58l.57,3.13c1-1.77,4-3.7,7.63-3.7,8.25.05,11.38,4.49,11.38,13.71V213.8H535.4Z"
        transform="translate(-83.62 -92.12)"
      />
      <path
        fill={fill2}
        d="M574.89,213.8h-5.63l-.57-3.58a10.55,10.55,0,0,1-8.31,4.15c-7.23,0-12.46-6.09-12.46-15s4.66-15,12.46-15a10.94,10.94,0,0,1,8.31,4V173.74h6.2Zm-13.31-4.61c4.44,0,7.11-3.47,7.11-9.79s-2.67-9.73-7.11-9.73-7.4,3.47-7.4,9.73S557.14,209.19,561.58,209.19Z"
        transform="translate(-83.62 -92.12)"
      />
      <path
        fill={fill2}
        d="M602.55,203.79c1.88.51,3.76,1.08,5.63,1.65-1.3,4.15-5.23,8.93-13.08,8.93s-13.55-6.09-13.55-15,5.24-15,13.26-15,13,5.35,13,14.05a21.56,21.56,0,0,1-.12,2.73H587.92c.12,4.22,3,8,7.18,8A7.58,7.58,0,0,0,602.55,203.79Zm-1.31-7.17c-.17-4.27-2.22-7-6.43-7s-6.43,3.41-6.6,7Z"
        transform="translate(-83.62 -92.12)"
      />
      <path
        fill={fill2}
        d="M611.83,189.67V185h4.95V176.7h6.14V185h6v4.66h-6V206c0,2.27.69,3,2.68,3,.85,0,1.76-.11,2.73-.11v5l-3.64.23H624c-5.41,0-7.17-2.9-7.17-8.42V189.67Z"
        transform="translate(-83.62 -92.12)"
      />
    </svg>
  )
}

export function NewLogoSvg({
  fill1 = "#6eb6c2",
  fill2 = "#004357",
  width,
  height,
  className,
}) {
  return (
    <svg
      id="hvlskape"
      data-name="hvlskape"
      className={className}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 173 159"
    >
      <rect
        fill={fill1}
        x="84.84"
        y="5.15"
        width="2.4"
        height="48.63"
        transform="translate(18.73 90.63) rotate(-61.16)"
      />
      <rect
        fill={fill1}
        x="52.85"
        y="23.57"
        width="2.4"
        height="23.78"
        transform="translate(-3.08 65.71) rotate(-61.16)"
      />
      <rect
        fill={fill1}
        x="95.42"
        y="47.24"
        width="0.8"
        height="24.31"
        transform="translate(-2.42 114.68) rotate(-61.16)"
      />
      <rect
        fill={fill1}
        x="63.42"
        y="41.78"
        width="2.4"
        height="48.34"
        transform="translate(-24.32 90.75) rotate(-61.16)"
      />
      <rect
        fill={fill1}
        x="61.62"
        y="28.27"
        width="48.29"
        height="2.4"
        transform="translate(-3.52 45.37) rotate(-29.06)"
      />
      <rect
        fill={fill1}
        x="83.01"
        y="64.82"
        width="48.29"
        height="2.4"
        transform="translate(-18.57 60.33) rotate(-29.05)"
      />
      <rect
        fill={fill1}
        x="105.63"
        y="34.13"
        width="24.15"
        height="2.4"
        transform="translate(-2.34 61.62) rotate(-29.06)"
      />
      <rect
        fill={fill1}
        x="63.42"
        y="59.21"
        width="24.15"
        height="0.8"
        transform="translate(-19.46 44.22) rotate(-29.09)"
      />
      <rect fill={fill1} x="63.27" y="41.19" width="2.4" height="49.65" />
      <rect fill={fill1} x="84.66" y="77.6" width="2.4" height="24.01" />
      <rect fill={fill1} x="85.46" y="29.47" width="0.8" height="24.82" />
      <rect fill={fill1} x="106.14" y="41.19" width="2.4" height="49.08" />
      <path
        fill={fill1}
        d="M85.86,103.54a1.19,1.19,0,0,1-.58-.15L43.06,80.16a1.2,1.2,0,0,1-.62-1V29.47a1.2,1.2,0,0,1,.62-1L85.27,5a1.17,1.17,0,0,1,1.16,0L129,28.42a1.2,1.2,0,0,1,.62,1V78.2a1.19,1.19,0,0,1-.61,1L86.45,103.38A1.16,1.16,0,0,1,85.86,103.54Zm-41-25.14,41,22.56,41.4-23.46V30.18L85.86,7.39l-41,22.78Z"
      />
      <path
        fill={fill2}
        d="M18.66,132.61q0-4-1.32-5.8t-4.57-1.89a5,5,0,0,0-4.42,1.93A9.92,9.92,0,0,0,7,132.61V145H3.28v-31.9H7L6.93,125a10.66,10.66,0,0,1,1-1.13,7,7,0,0,1,1.4-1.06,9,9,0,0,1,1.83-.79,7.71,7.71,0,0,1,2.28-.31c3.16,0,5.42.94,6.79,2.75s2.05,4.53,2.05,8.17V145H18.66Z"
      />
      <path
        fill={fill2}
        d="M41.31,122.12H45.1L36.75,145H33l-8.35-22.83h3.79l6.41,18Z"
      />
      <path fill={fill2} d="M52,113.18V145h-3.7V113.18Z" />
      <path
        fill={fill1}
        d="M59.63,137.28a7.22,7.22,0,0,0,.29,1.55,4.17,4.17,0,0,0,.91,1.58,4.91,4.91,0,0,0,1.78,1.24,7,7,0,0,0,2.89.5A14.47,14.47,0,0,0,67,142a6.76,6.76,0,0,0,1.88-.52,4.6,4.6,0,0,0,1.6-1.17,3,3,0,0,0,.67-2.07,2.58,2.58,0,0,0-1-2.32,7.4,7.4,0,0,0-2.69-1c-1.08-.19-2.24-.34-3.47-.45a13.37,13.37,0,0,1-3.48-.74A6.18,6.18,0,0,1,57.76,132a5.55,5.55,0,0,1-1.06-3.71,5.38,5.38,0,0,1,.66-2.61,6.59,6.59,0,0,1,1.78-2.09,8.43,8.43,0,0,1,2.65-1.38,10.75,10.75,0,0,1,3.3-.49,13.36,13.36,0,0,1,3.47.43,8.27,8.27,0,0,1,2.89,1.35,7.09,7.09,0,0,1,2,2.32,8,8,0,0,1,.9,3.3c-.6.09-1.21.19-1.82.31l-1.83.36a4.59,4.59,0,0,0-1.58-3.7,7.34,7.34,0,0,0-5.87-.85,5.18,5.18,0,0,0-1.51.74,3.8,3.8,0,0,0-1,1.08,2.65,2.65,0,0,0,1,3.66,11.76,11.76,0,0,0,3.81.88,31.5,31.5,0,0,1,3.59.54,9.72,9.72,0,0,1,2.94,1.1,5.48,5.48,0,0,1,2.72,5.13,6.26,6.26,0,0,1-.7,3,6.56,6.56,0,0,1-1.92,2.21,8.89,8.89,0,0,1-2.94,1.38,14.39,14.39,0,0,1-3.76.47,12.26,12.26,0,0,1-4.09-.62,8.35,8.35,0,0,1-2.9-1.67,7.48,7.48,0,0,1-1.76-2.38A8.69,8.69,0,0,1,56,138Z"
      />
      <path
        fill={fill1}
        d="M92.79,122.12h5.1l-9.7,9.2,1.06,1.31c.56.69,1.21,1.51,2,2.46s1.57,2,2.44,3l2.44,3c.75.95,1.4,1.77,2,2.46s.91,1.13,1.06,1.31H94.69l-9-11.19-3.11,3V145h-3.7V113.18h3.7V132Z"
      />
      <path
        fill={fill1}
        d="M104.48,129.88l-1.81-.36c-.6-.12-1.2-.23-1.8-.32a7.77,7.77,0,0,1,.84-3.31,6.91,6.91,0,0,1,2-2.35,8.82,8.82,0,0,1,2.83-1.4,11.86,11.86,0,0,1,3.41-.47q5,0,7.2,2.38t2.21,7.3v7.92a5.75,5.75,0,0,0,.27,2,1.12,1.12,0,0,0,1.18.7,4.07,4.07,0,0,0,1.21-.18V145a8.92,8.92,0,0,1-1.33.22c-.37,0-.71.05-1,.05a3.55,3.55,0,0,1-2.76-1,4.19,4.19,0,0,1-1.08-2.59,8.06,8.06,0,0,1-.85,1,9.73,9.73,0,0,1-1.49,1.21,9.47,9.47,0,0,1-5.1,1.56,9.08,9.08,0,0,1-6.21-1.94,6.5,6.5,0,0,1-2.18-5.1,5.74,5.74,0,0,1,.58-2.55,6.16,6.16,0,0,1,1.74-2.1,8.89,8.89,0,0,1,2.82-1.42,13.3,13.3,0,0,1,3.88-.52h6.63v-1.94a8.19,8.19,0,0,0-.18-1.67,3.74,3.74,0,0,0-.76-1.58,4.38,4.38,0,0,0-1.74-1.19,8.07,8.07,0,0,0-3-.47Q104.57,124.92,104.48,129.88Zm11.14,4.87h-6.34a8.88,8.88,0,0,0-2.85.38,5.19,5.19,0,0,0-1.7.92,2.59,2.59,0,0,0-.81,1.17,3.58,3.58,0,0,0-.21,1.12,3.79,3.79,0,0,0,.27,1.37,3.38,3.38,0,0,0,.84,1.23,4.18,4.18,0,0,0,1.5.87,6.56,6.56,0,0,0,2.28.34,7.84,7.84,0,0,0,3.08-.56,6.55,6.55,0,0,0,2.2-1.48,5.9,5.9,0,0,0,1.31-2,5.82,5.82,0,0,0,.43-2.17Z"
      />
      <path
        fill={fill1}
        d="M125.59,122.12h3.25l.45,3.25a8.55,8.55,0,0,1,.88-1,9.13,9.13,0,0,1,3.56-2.23,7.6,7.6,0,0,1,2.63-.43,8.47,8.47,0,0,1,3.87.88,9.36,9.36,0,0,1,3,2.43,11.57,11.57,0,0,1,2,3.75,15.29,15.29,0,0,1,.72,4.8,16.5,16.5,0,0,1-.66,4.83,11.29,11.29,0,0,1-1.88,3.75,8.46,8.46,0,0,1-3,2.43,9.26,9.26,0,0,1-4.07.86,8.52,8.52,0,0,1-2.61-.38,9,9,0,0,1-2.05-1,8.39,8.39,0,0,1-1.48-1.2,9.13,9.13,0,0,1-.93-1.08v12.09h-3.7Zm16.65,11.39a10.31,10.31,0,0,0-1.73-6.28,5.73,5.73,0,0,0-4.85-2.31,5.42,5.42,0,0,0-4.74,2.31,13,13,0,0,0,0,12.6,5.41,5.41,0,0,0,4.74,2.32,5.71,5.71,0,0,0,4.85-2.32A10.35,10.35,0,0,0,142.24,133.51Z"
      />
      <path
        fill={fill1}
        d="M170.08,138.05a9.28,9.28,0,0,1-1,2.5,9,9,0,0,1-2,2.36,10.73,10.73,0,0,1-3,1.78,11.05,11.05,0,0,1-4.11.71,10,10,0,0,1-4.15-.86,9.43,9.43,0,0,1-3.27-2.43,11.61,11.61,0,0,1-2.15-3.75,14.4,14.4,0,0,1-.76-4.83,15.29,15.29,0,0,1,.72-4.8,11.31,11.31,0,0,1,2.05-3.77,9.16,9.16,0,0,1,3.23-2.44,10.19,10.19,0,0,1,4.24-.85,9.25,9.25,0,0,1,7.35,3c1.78,2,2.67,4.84,2.67,8.48,0,.24,0,.5,0,.77s0,.54-.06.81h-16.2a8.43,8.43,0,0,0,.45,2.72,7.78,7.78,0,0,0,1.29,2.37,6.34,6.34,0,0,0,2,1.68,5.46,5.46,0,0,0,2.62.63,7.24,7.24,0,0,0,4.06-1.19,6.59,6.59,0,0,0,2.57-3.77Zm-4.15-6.28a8.55,8.55,0,0,0-1.49-5,5.32,5.32,0,0,0-4.51-1.83,5.7,5.7,0,0,0-2.66.58,6.13,6.13,0,0,0-1.92,1.54,6.39,6.39,0,0,0-1.17,2.2,9.45,9.45,0,0,0-.43,2.53Z"
      />
    </svg>
  )
}

export function WaveSvg({ fill1, width, className, height }) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 1440 320"
    >
      <path
        fill={fill1}
        fill-opacity="1"
        d="M0,192L80,208C160,224,320,256,480,272C640,288,800,288,960,282.7C1120,277,1280,267,1360,261.3L1440,256L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
      ></path>
    </svg>
  )
}

export function StudentSvg({
  fill1,
  fill2,
  fill3,
  fill4,
  width,
  height,
  className,
}) {
  return (
    <svg
      id="b3d864f8-84bf-4ad6-ac45-a7174feef3c9"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 839.3867 834.64548"
      className={className}
    >
      <title>processing</title>
      <path
        d="M749.90348,256.796q15.11009,7.97595,29.54036,16.32368"
        transform="translate(-180.55665 -32.42726)"
        fill="none"
        stroke={fill1}
        strokeMiterlimit="10"
      />
      <path
        d="M562.44148,223.84865c43.93909,9.501,91.91,25.90257,140.23563,49.05024a670.77416,670.77416,0,0,1,100.10271,58.75124"
        transform="translate(-180.55665 -32.42726)"
        fill="none"
        stroke={fill1}
        strokeMiterlimit="10"
      />
      <line
        x1="235.63007"
        y1="832.57322"
        x2="686.1635"
        y2="832.57322"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
      <path
        d="M521.327,216.81667c13.20369,1.62338,26.94958,3.9691,41.11448,7.032"
        transform="translate(-180.55665 -32.42726)"
        fill="none"
        stroke={fill1}
        strokeMiterlimit="10"
      />
      <path
        d="M392.11174,354.5821c-24.39394-35.69909-32.77388-69.42793-20.24052-95.594,13.86242-28.94072,50.969-43.745,101.26849-44.83538"
        transform="translate(-180.55665 -32.42726)"
        fill="none"
        stroke={fill1}
        strokeMiterlimit="10"
      />
      <path
        d="M905.80958,434.31808c20.33267,32.73115,26.64112,63.41772,15.044,87.62915-10.20553,21.3062-33.0092,34.95053-64.40046,41.10164"
        transform="translate(-180.55665 -32.42726)"
        fill="none"
        stroke={fill1}
        strokeMiterlimit="10"
      />
      <path
        d="M802.77982,331.65013a498.103,498.103,0,0,1,57.61868,47.75645"
        transform="translate(-180.55665 -32.42726)"
        fill="none"
        stroke={fill1}
        strokeMiterlimit="10"
      />
      <path
        d="M378.60511,172.31546c40.4919-8.699,92.89066-7.15266,151.49348,4.39916"
        transform="translate(-180.55665 -32.42726)"
        fill="none"
        stroke={fill1}
        strokeMiterlimit="10"
      />
      <path
        d="M967.94282,434.31434c18.12773,26.47986,29.27651,52.10442,32.19046,75.44012"
        transform="translate(-180.55665 -32.42726)"
        fill="none"
        stroke={fill1}
        strokeMiterlimit="10"
      />
      <path
        d="M779.44384,273.11963q27.27426,15.76946,51.89931,32.61952"
        transform="translate(-180.55665 -32.42726)"
        fill="none"
        stroke={fill1}
        strokeMiterlimit="10"
      />
      <path
        d="M805.50068,663.14119a25.07063,25.07063,0,0,0-21.61027,15.10974c-5.40367,13.062,1.55395,28.40526,11.99861,37.9302s23.86146,14.96932,36.05174,22.12559c16.37359,9.61206,31.08659,22.95519,39.27922,40.08315s9.064,38.34453-.56607,54.70753c-8.93834,15.18767-25.1912,24.27742-40.69221,32.66062"
        transform="translate(-180.55665 -32.42726)"
        fill="none"
        stroke="#3f3d56"
        strokeMiterlimit="10"
      />
      <ellipse
        cx="638.71022"
        cy="631.8513"
        rx="19.0904"
        ry="9.27248"
        fill="#57b894"
      />
      <ellipse
        cx="653.98254"
        cy="692.94058"
        rx="19.0904"
        ry="9.27248"
        fill="#57b894"
      />
      <ellipse
        cx="619.07438"
        cy="702.7585"
        rx="19.0904"
        ry="9.27248"
        fill="#57b894"
      />
      <ellipse
        cx="673.61838"
        cy="754.02986"
        rx="19.0904"
        ry="9.27248"
        fill="#57b894"
      />
      <ellipse
        cx="711.79918"
        cy="754.02986"
        rx="19.0904"
        ry="9.27248"
        fill="#57b894"
      />
      <path
        d="M703.19827,303.07461h10.75455s9.85834,76.17808,2.68864,83.34778-26.88638,11.65076-29.575,0,0-54.669,0-54.669Z"
        transform="translate(-180.55665 -32.42726)"
        fill={fill2}
      />
      <ellipse
        cx="452.28893"
        cy="204.32762"
        rx="65.42352"
        ry="76.62618"
        stroke={fill1}
        fill={fill4}
      />
      <path
        d="M618.05807,251.09428s3.58485,29.575-7.1697,35.8485,12.547,42.122,12.547,42.122l27.7826,7.1697,18.82046-25.99017-3.58485-26.88638s-21.5091-21.5091-11.65076-41.22578Z"
        transform="translate(-180.55665 -32.42726)"
        fill={fill3}
      />
      <path
        d="M618.05807,251.09428s3.58485,29.575-7.1697,35.8485,12.547,42.122,12.547,42.122l27.7826,7.1697,18.82046-25.99017-3.58485-26.88638s-21.5091-21.5091-11.65076-41.22578Z"
        transform="translate(-180.55665 -32.42726)"
        opacity="0.1"
      />
      <path
        d="M577.7285,572.1358s-10.75455,97.68718,0,120.98871,39.43336,88.72505,39.43336,88.72505L608.19973,800.67l24.19774-5.37728,9.85834-18.82046s-11.65076-45.70685-13.44319-61.83868S613.577,668.92677,613.577,668.92677l25.094-106.64931Z"
        transform="translate(-180.55665 -32.42726)"
        fill={fill3}
      />
      <path
        d="M662.8687,555.80658s.89621,98.58339,7.1697,113.8754l-3.58485,133.53569,19.71668,2.63224S714.849,693.82333,710.368,666.937s.89621-123.67735.89621-123.67735Z"
        transform="translate(-180.55665 -32.42726)"
        fill={fill3}
      />
      <circle cx="452.28893" cy="196.70981" r="34.05608" fill={fill3} />
      <path
        d="M748.90512,568.35356s-24.19775,16.13182-34.05608,4.48106-130.847,7.1697-146.08267,3.58485,8.06592-60.94246,8.06592-90.51748c0-22.12749,8.03-55.78924,12.072-71.34747,1.36226-5.23391,2.26744-8.41545,2.26744-8.41545s9.19515-26.16045,7.47449-37.03152a9.35068,9.35068,0,0,0-.67216-2.39287,4.9131,4.9131,0,0,0-.52884-.90518c-4.2929-5.72682-16.59788-47.4903-21.37465-65.42353a80.965,80.965,0,0,1-1.92688-8.06591c0-2.68864,33.15987-10.75455,41.44983-11.13992,8.29894-.38536,10.5305,11.13992,10.5305,11.13992,2.68864,1.79242,15.23562,33.15987,15.23562,33.15987l11.65076-29.575s-.89621-6.27349-1.79242-6.27349,13.255-8.46025,13.255-8.46025,14.52757,10.25268,22.59348,8.46025,26.88638,14.3394,26.88638,14.3394-33.15987,83.34778-19.71668,101.272S748.90512,568.35356,748.90512,568.35356Z"
        transform="translate(-180.55665 -32.42726)"
        fill={fill2}
      />
      <path
        d="M639.56717,772.88744s-15.23561,15.23561-15.23561,17.028l-12.547-5.37728s-5.37728,19.71668-6.27349,22.40532-16.13183,26.88638-31.36744,37.64093,0,24.19774,13.44319,21.5091,47.49927-33.15987,47.49927-33.15987,23.30153,0,31.36744-17.92425C666.45355,815.00943,644.94445,771.095,639.56717,772.88744Z"
        transform="translate(-180.55665 -32.42726)"
        stroke={fill1}
        fill={fill4}
      />
      <path
        d="M684.37781,798.73661s-23.02339-16.72833-24.50678-1.19447-1.48339,49.58995-1.48339,49.58995-7.1697,22.40532,15.23561,19.71668,24.19774-8.96213,24.19774-8.96213l-10.75455-43.01821V796.048Z"
        transform="translate(-180.55665 -32.42726)"
        stroke={fill1}
        fill={fill4}
      />
      <path
        d="M597.44518,365.8095c-4.2929-5.72682-16.59788-47.4903-21.37465-65.42353h4.34661S591.333,343.10842,597.974,366.71468A4.9131,4.9131,0,0,0,597.44518,365.8095Z"
        transform="translate(-180.55665 -32.42726)"
        opacity="0.1"
      />
      <path
        d="M656.59521,424.95953s-12.547.89621-58.25382-8.06591a91.37416,91.37416,0,0,1-9.43714-2.3391c1.36226-5.23391,2.26744-8.41545,2.26744-8.41545s9.19515-26.16045,7.47449-37.03152c2.14185,7.52816,3.73714,12.60972,4.17627,12.83377,1.79243.89622,59.15,10.75456,67.216,14.33941S656.59521,424.95953,656.59521,424.95953Z"
        transform="translate(-180.55665 -32.42726)"
        opacity="0.1"
      />
      <path
        d="M672.727,307.66416s24.71954-3.05,26.25107.958,7.805,42.74354,10.49365,47.70963,3.58485,35.75582-7.1697,36.749-25.094-9.93217-22.40532-18.87113S672.727,307.66416,672.727,307.66416Z"
        transform="translate(-180.55665 -32.42726)"
        opacity="0.1"
      />
      <path
        d="M660.18006,390.00724s37.64093,4.48106,43.91442,0,32.26366-4.48106,26.88638,8.06591-51.98033,13.44319-51.98033,13.44319H652.11415Z"
        transform="translate(-180.55665 -32.42726)"
        fill={fill3}
      />
      <path
        d="M580.41714,294.11248h-6.27349s-12.547,2.68864-10.75455,42.122-10.75455,65.42352,34.95229,74.38565,58.25382,8.06591,58.25382,8.06591,21.50911-25.094,13.44319-28.6788-65.42352-13.44319-67.216-14.3394S580.41714,294.11248,580.41714,294.11248Z"
        transform="translate(-180.55665 -32.42726)"
        fill={fill2}
      />
      <path
        d="M698.28935,311.80158s-15.704-58.91488-28.25095-58.91488-26.88638,2.68864-16.13183,14.33941,24.19775,24.19774,25.094,30.47123,4.48106,15.23561,4.48106,15.23561Z"
        transform="translate(-180.55665 -32.42726)"
        fill={fill3}
      />
      <path
        d="M676.31189,307.66416s24.71954-3.05,26.25107.958,7.805,42.74354,10.49365,47.70963,3.58485,35.75582-7.1697,36.749-25.094-9.93217-22.40532-18.87113S676.31189,307.66416,676.31189,307.66416Z"
        transform="translate(-180.55665 -32.42726)"
        stroke={fill2}
      />
      <circle cx="443.3268" cy="164.44615" r="32.26366" fill={fill4} />
      <path
        d="M753.8938,561.5946c-50.06781-8.25192-106.70905-26.18981-163.84611-53.55811-65.05663-31.16171-120.1839-69.48522-159.465-108.21652"
        transform="translate(-180.55665 -32.42726)"
        fill="none"
        stroke={fill1}
        strokeMiterlimit="10"
      />
      <path
        d="M550.89321,524.8925c-44.792-23.20619-85.34351-49.00584-120.31229-75.86668"
        transform="translate(-180.55665 -32.42726)"
        fill="none"
        stroke={fill1}
        strokeMiterlimit="10"
      />
      <circle cx="53.70022" cy="76.07809" r="53.70022" fill={fill2} />
      <circle
        cx="66.29657"
        cy="54.20022"
        r="53.70022"
        fill="none"
        stroke={fill1}
        strokeMiterlimit="10"
      />
      <circle cx="800.31415" cy="270.04378" r="31.24377" fill="#f2f2f2" />
      <circle
        cx="807.64293"
        cy="257.31484"
        r="31.24377"
        fill="none"
        stroke={fill1}
        strokeMiterlimit="10"
      />
      <circle cx="230.36251" cy="595.72871" r="44.91291" fill="#f2f2f2" />
      <circle
        cx="240.89764"
        cy="577.43086"
        r="44.91291"
        fill="none"
        stroke={fill1}
        strokeMiterlimit="10"
      />
    </svg>
  )
}

export function EventSvg({
  fill1,
  fill2,
  fill3,
  fill4,
  width,
  height,
  className,
}) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      id="53917d2c-59b7-443c-9dc6-ca37ebed1a74"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 965.64 800.81"
    >
      <defs>
        <linearGradient
          id="5075b4d4-ce6f-410a-b63e-15cf2dd063e7"
          x1="478.38"
          y1="695.72"
          x2="478.38"
          y2="33.2"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="gray" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="gray" stopOpacity="0.12" />
          <stop offset="1" stopColor="gray" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="dee71953-6e04-4c93-a054-139b0acb5af5"
          x1="1834.21"
          y1="806.87"
          x2="1834.21"
          y2="194.98"
          gradientTransform="matrix(-1, 0, 0, 1, 2766, 0)"
        />
      </defs>
      <title>events</title>
      <path
        d="M1019.06,492.09c-37.09,60-30.14,141.74-13.85,206.19,5.45,21.58,11.73,44.36,7.07,68.51-5.7,29.53-26.48,52.72-47.51,66-38.34,24.15-82.12,23.48-114-1.77-27.53-21.81-45.66-59.85-73.77-80.49-47-34.55-111.54-13.61-167.58,18.68C569.79,792,526.17,821,489.54,804.58c-25.77-11.53-41.84-44.06-49.34-79.46-3.62-17.09-5.81-35.76-14.11-48.78-4.94-7.74-11.73-12.92-18.77-17.17-64.25-38.74-151.18-6.35-213.14-50.22-41.85-29.62-65.64-90.8-73.46-156.22s-1.39-135.42,6.66-204.34c5.73-49,13.48-101.42,39.2-141.27,27.21-42.15,70.1-61,107.38-57s69.65,27.62,97.55,56.49c34.87,36.08,66.32,82.16,111.45,93.8,30.74,7.93,64.31-1.25,97-6.44,54.58-8.68,108.4-6.19,161.84-2.93,51.16,3.12,102.63,7,150.3,25,33.73,12.75,59.62,39.29,91.1,56.55,20.52,11.25,43.67,12.68,62.79,27.68,23.55,18.48,43.93,52.59,34.5,95.71C1071.5,437,1038.74,460.25,1019.06,492.09Z"
        transform="translate(-117.18 -49.59)"
        fill={fill1}
        opacity="0.1"
      />
      <path
        d="M781.67,356.47s101-84.63,123.46-127.3,51.05-79.44,51.05-79.44"
        transform="translate(-117.18 -49.59)"
        fill="none"
        stroke="#535461"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <path
        d="M942.9,245.12l-43.69-9.85S909.52,281.35,942.9,245.12Z"
        transform="translate(-117.18 -49.59)"
        fill={fill1}
      />
      <path
        d="M876.14,198.08l25.14,37.65S852.13,239.28,876.14,198.08Z"
        transform="translate(-117.18 -49.59)"
        fill={fill1}
      />
      <path
        d="M960.09,209.73l-39.93-7S945.24,240.88,960.09,209.73Z"
        transform="translate(-117.18 -49.59)"
        fill={fill1}
      />
      <path
        d="M901,176.08l19.67,25.76S885.85,205.14,901,176.08Z"
        transform="translate(-117.18 -49.59)"
        fill={fill1}
      />
      <path
        d="M940.81,170.95s34,1.62,38,4.87-5.07,22.3-18,19.19S940.81,170.95,940.81,170.95Z"
        transform="translate(-117.18 -49.59)"
        fill={fill1}
      />
      <path
        d="M927.22,140.56s13.89,25.31,13.37,30.13-21.17,10.23-25.34-8S927.22,140.56,927.22,140.56Z"
        transform="translate(-117.18 -49.59)"
        fill={fill1}
      />
      <circle
        cx="963.75"
        cy="154.12"
        r="9.49"
        transform="matrix(0.3, -0.95, 0.95, 0.3, 408.68, 976.78)"
        fill="#ffd037"
      />
      <circle
        cx="948.9"
        cy="144.43"
        r="9.49"
        transform="matrix(0.3, -0.95, 0.95, 0.3, 407.54, 955.85)"
        fill="#ffd037"
      />
      <path
        d="M466.64,688.22S482.85,640,380.09,581.87c0,0-104.34-30.43-93.67-134.93,0,0-120.28,88.12-38.89,200.37s151.64,87.75,151.64,87.75Z"
        transform="translate(-117.18 -49.59)"
        fill={fill1}
      />
      <path
        d="M435.31,710s-50.14-54.19-98.2-75.14A95.71,95.71,0,0,1,311,617.47c-30.64-28-44-70.25-36.1-111l11.55-59.53"
        transform="translate(-117.18 -49.59)"
        fill="none"
        stroke="#535461"
        strokeMiterlimit="10"
        strokeWidth="2"
      />
      <rect
        x="193.07"
        y="33.2"
        width="570.61"
        height="662.53"
        fill="url(#5075b4d4-ce6f-410a-b63e-15cf2dd063e7)"
      />
      <rect
        x="199.72"
        y="40.91"
        width="557.32"
        height="647.09"
        fill="#f7f7fd"
      />
      <rect
        x="233.38"
        y="113.85"
        width="140.89"
        height="11.22"
        fill="#e2e2ec"
      />
      <rect
        x="233.38"
        y="141.28"
        width="240.63"
        height="11.22"
        fill="#e2e2ec"
      />
      <rect x="630.36" y="103.13" width="99.99" height="86.28" fill="#fff" />
      <path
        d="M847,153.22V238.5H748V153.22h99m1-1H747V239.5H848V152.22Z"
        transform="translate(-117.18 -49.59)"
        fill="#e2e2ec"
      />
      <rect x="529.37" y="103.13" width="99.99" height="86.28" fill="#fff" />
      <path
        d="M746,153.22V238.5h-99V153.22h99m1-1h-101V239.5H747V152.22Z"
        transform="translate(-117.18 -49.59)"
        fill="#e2e2ec"
      />
      <rect x="630.36" y="190.41" width="99.99" height="86.28" fill="#fff" />
      <path
        d="M847,240.5v85.28H748V240.5h99m1-1H747v87.28H848V239.5Z"
        transform="translate(-117.18 -49.59)"
        fill="#e2e2ec"
      />
      <rect x="529.37" y="190.41" width="99.99" height="86.28" fill="#fff" />
      <path
        d="M746,240.5v85.28h-99V240.5h99m1-1h-101v87.28H747V239.5Z"
        transform="translate(-117.18 -49.59)"
        fill="#e2e2ec"
      />
      <rect x="428.38" y="190.41" width="99.99" height="86.28" fill="#fff" />
      <path
        d="M645.05,240.5v85.28h-99V240.5h99m1-1h-101v87.28h101V239.5Z"
        transform="translate(-117.18 -49.59)"
        fill="#e2e2ec"
      />
      <rect x="327.39" y="190.41" width="99.99" height="86.28" fill="#fff" />
      <path
        d="M544.06,240.5v85.28h-99V240.5h99m1-1h-101v87.28h101V239.5Z"
        transform="translate(-117.18 -49.59)"
        fill="#e2e2ec"
      />
      <rect x="226.4" y="190.41" width="99.99" height="86.28" fill="#fff" />
      <path
        d="M443.07,240.5v85.28h-99V240.5h99m1-1h-101v87.28h101V239.5Z"
        transform="translate(-117.18 -49.59)"
        fill="#e2e2ec"
      />
      <rect x="630.36" y="277.68" width="99.99" height="86.28" fill="#fff" />
      <path
        d="M847,327.78v85.28H748V327.78h99m1-1H747v87.28H848V326.78Z"
        transform="translate(-117.18 -49.59)"
        fill="#e2e2ec"
      />
      <rect x="529.37" y="277.68" width="99.99" height="86.28" fill="#fff" />
      <path
        d="M746,327.78v85.28h-99V327.78h99m1-1h-101v87.28H747V326.78Z"
        transform="translate(-117.18 -49.59)"
        fill="#e2e2ec"
      />
      <rect x="428.38" y="277.68" width="99.99" height="86.28" fill="#fff" />
      <path
        d="M645.05,327.78v85.28h-99V327.78h99m1-1h-101v87.28h101V326.78Z"
        transform="translate(-117.18 -49.59)"
        fill="#e2e2ec"
      />
      <rect x="327.39" y="277.68" width="99.99" height="86.28" fill="#fff" />
      <path
        d="M544.06,327.78v85.28h-99V327.78h99m1-1h-101v87.28h101V326.78Z"
        transform="translate(-117.18 -49.59)"
        fill="#e2e2ec"
      />
      <rect x="226.4" y="277.68" width="99.99" height="86.28" fill="#fff" />
      <path
        d="M443.07,327.78v85.28h-99V327.78h99m1-1h-101v87.28h101V326.78Z"
        transform="translate(-117.18 -49.59)"
        fill="#e2e2ec"
      />
      <rect x="630.36" y="364.96" width="99.99" height="86.28" fill="#fff" />
      <path
        d="M847,415.05v85.28H748V415.05h99m1-1H747v87.28H848V414.05Z"
        transform="translate(-117.18 -49.59)"
        fill="#e2e2ec"
      />
      <rect x="529.37" y="364.96" width="99.99" height="86.28" fill="#fff" />
      <path
        d="M746,415.05v85.28h-99V415.05h99m1-1h-101v87.28H747V414.05Z"
        transform="translate(-117.18 -49.59)"
        fill="#e2e2ec"
      />
      <rect x="428.38" y="364.96" width="99.99" height="86.28" fill="#fff" />
      <path
        d="M645.05,415.05v85.28h-99V415.05h99m1-1h-101v87.28h101V414.05Z"
        transform="translate(-117.18 -49.59)"
        fill="#e2e2ec"
      />
      <rect x="327.39" y="364.96" width="99.99" height="86.28" fill="#fff" />
      <path
        d="M544.06,415.05v85.28h-99V415.05h99m1-1h-101v87.28h101V414.05Z"
        transform="translate(-117.18 -49.59)"
        fill="#e2e2ec"
      />
      <rect x="226.4" y="364.96" width="99.99" height="86.28" fill="#fff" />
      <path
        d="M443.07,415.05v85.28h-99V415.05h99m1-1h-101v87.28h101V414.05Z"
        transform="translate(-117.18 -49.59)"
        fill="#e2e2ec"
      />
      <rect x="630.36" y="452.23" width="99.99" height="86.28" fill="#fff" />
      <path
        d="M847,502.33V587.6H748V502.33h99m1-1H747V588.6H848V501.33Z"
        transform="translate(-117.18 -49.59)"
        fill="#e2e2ec"
      />
      <rect x="529.37" y="452.23" width="99.99" height="86.28" fill="#fff" />
      <path
        d="M746,502.33V587.6h-99V502.33h99m1-1h-101V588.6H747V501.33Z"
        transform="translate(-117.18 -49.59)"
        fill="#e2e2ec"
      />
      <rect x="428.38" y="452.23" width="99.99" height="86.28" fill="#fff" />
      <path
        d="M645.05,502.33V587.6h-99V502.33h99m1-1h-101V588.6h101V501.33Z"
        transform="translate(-117.18 -49.59)"
        fill="#e2e2ec"
      />
      <rect x="327.39" y="452.23" width="99.99" height="86.28" fill="#fff" />
      <path
        d="M544.06,502.33V587.6h-99V502.33h99m1-1h-101V588.6h101V501.33Z"
        transform="translate(-117.18 -49.59)"
        fill="#e2e2ec"
      />
      <rect x="226.4" y="452.23" width="99.99" height="86.28" fill="#fff" />
      <path
        d="M443.07,502.33V587.6h-99V502.33h99m1-1h-101V588.6h101V501.33Z"
        transform="translate(-117.18 -49.59)"
        fill="#e2e2ec"
      />
      <rect x="630.36" y="539.51" width="99.99" height="86.28" fill="#fff" />
      <path
        d="M847,589.6v85.28H748V589.6h99m1-1H747v87.28H848V588.6Z"
        transform="translate(-117.18 -49.59)"
        fill="#e2e2ec"
      />
      <rect x="529.37" y="539.51" width="99.99" height="86.28" fill="#fff" />
      <path
        d="M746,589.6v85.28h-99V589.6h99m1-1h-101v87.28H747V588.6Z"
        transform="translate(-117.18 -49.59)"
        fill="#e2e2ec"
      />
      <rect x="428.38" y="539.51" width="99.99" height="86.28" fill="#fff" />
      <path
        d="M645.05,589.6v85.28h-99V589.6h99m1-1h-101v87.28h101V588.6Z"
        transform="translate(-117.18 -49.59)"
        fill="#e2e2ec"
      />
      <rect x="327.39" y="539.51" width="99.99" height="86.28" fill="#fff" />
      <path
        d="M544.06,589.6v85.28h-99V589.6h99m1-1h-101v87.28h101V588.6Z"
        transform="translate(-117.18 -49.59)"
        fill="#e2e2ec"
      />
      <rect x="226.4" y="539.51" width="99.99" height="86.28" fill="#fff" />
      <path
        d="M443.07,589.6v85.28h-99V589.6h99m1-1h-101v87.28h101V588.6Z"
        transform="translate(-117.18 -49.59)"
        fill="#e2e2ec"
      />
      <path
        d="M553.6,754.48c2.86-2.17,5.55-4.73,6.38-7.85a6.64,6.64,0,0,0-4.46-8c-4.09-1.28-8.46,1-11.78,3.37s-7.11,5-11.45,4.51c4.49-3.25,6.63-8.52,5.39-13.31a5.16,5.16,0,0,0-1.5-2.7c-2.27-2-6.39-1.13-9.11.43-8.65,5-11.06,14.55-11.11,23.18-.87-3.11-.14-6.35-.16-9.55s-1.09-6.73-4.39-8.45a15.71,15.71,0,0,0-6.7-1.29c-3.89-.12-8.22.2-10.87,2.52-3.3,2.88-2.44,7.72.43,10.89s7.24,5.16,11.25,7.35c3.06,1.67,6.16,3.62,8,6.26a5.68,5.68,0,0,1,.6,1.12h24.37A70.07,70.07,0,0,0,553.6,754.48Z"
        transform="translate(-117.18 -49.59)"
        fill={fill1}
      />
      <circle cx="239.59" cy="203.53" r="5.7" fill={fill3} />
      <circle cx="253.83" cy="203.53" r="5.7" fill="#ffd037" />
      <circle cx="440.87" cy="288.03" r="5.7" fill={fill1} />
      <circle cx="455.12" cy="288.03" r="5.7" fill={fill1} />
      <circle cx="745.64" cy="451.33" r="5.7" fill={fill1} />
      <circle cx="748.49" cy="444.69" r="5.7" fill={fill1} />
      <circle cx="751.34" cy="438.99" r="5.7" fill={fill1} />
      <circle cx="645" cy="203.53" r="5.7" fill={fill1} />
      <circle cx="659.24" cy="203.53" r="5.7" fill={fill1} />
      <circle cx="673.49" cy="203.53" r="5.7" fill={fill1} />
      <circle cx="687.73" cy="203.53" r="5.7" fill={fill1} />
      <circle cx="645" cy="380.12" r="5.7" fill={fill1} />
      <circle cx="659.24" cy="380.12" r="5.7" fill={fill1} />
      <circle cx="673.49" cy="380.12" r="5.7" fill={fill1} />
      <circle cx="687.73" cy="380.12" r="5.7" fill={fill1} />
      <circle cx="340.23" cy="288.03" r="5.7" fill="#ffd037" />
      <circle cx="239.59" cy="374.43" r="5.7" fill={fill3} />
      <path
        d="M857.9,515.22c6.25,3.69,16,6.09,28.35-1.84l-.12,17.56c.88,12,15.77,55.91,15.77,55.91l10.5,36.1a282.47,282.47,0,0,1,11.54,78.53c0,8.81.82,17.89,3.35,24.72,5.59,15.11-2.2,34.58-5.58,41.83a175.68,175.68,0,0,1-17.33,4.88,11.84,11.84,0,0,0-1.55.47l-.07-.1S887,779.53,880,778.67c-5.22-.64-8.49,5.87-4.4,10.66-3.86,1-7.82,1.71-10.49,1.38-7-.86-10.51,11.18,2.63,14.62s87.59,0,87.59,0a10.56,10.56,0,0,0,5.6-11.66c5.72-.22,9.29-.39,9.29-.39s13.14-6,0-22.37l-.7.53-2.81-8.27V752s16.64-80,11.39-89.46-7.88-57.63-7.88-57.63L972,552.44c4.75-9.8,7.18-25.16,8.41-37.17a78.87,78.87,0,0,0,3.36-17.63c.21-2.82.38-5.78.49-8.85A59.33,59.33,0,0,0,981,469c5-7.2,13.13-18.33,19.84-24.91,10.51-10.32,7.88-22.37,7.88-22.37l-4.38-29.25-10.51-49.89c1.32-13.84-1.57-21.81-4.76-26.31.76-4.47,1.47-8.94,2-13.43A171.87,171.87,0,0,0,988,244.36c-1.52-6.69-3.59-13.55-8.28-18.62-5.31-5.74-13.93-9.24-15.91-16.76-.49-1.87-.51-3.86-1.22-5.66-1.92-4.92-8-6.71-13.32-7.35-9.48-1.15-23.19-2-32.3,1.7-6.36,2.6-15.12,9.76-18.6,17.44-2,3.53-2.93,7.24-2.08,10.73a14.71,14.71,0,0,0,1.51,3.67A33,33,0,0,0,895.76,241a33.56,33.56,0,0,0,21.42,31.13c.21.66.4,1.31.56,1.95a33.42,33.42,0,0,1,.86,4.69c-.17,3-.36,6.09-.5,9.14q-.15.63-.32,1.24a225.38,225.38,0,0,0-22,11.22c5.26,7.74-.88,40.43-.88,40.43-13.14,7.74-26.28,27.53-12.26,48.17s6.13,43,6.13,43l-2.19,33.63h0v.06l-.44,6.73.17,0-.17,2.55.38.07,0,2.74c-6.15,2.26-15.14,6.82-20.5,15.25a53.81,53.81,0,0,1-9.17,11.25A6.76,6.76,0,0,0,857.9,515.22Zm105.76-89.36a32,32,0,0,1,3.11-16.92q3.09.06,6.19.07c.21,1.45.46,2.83.76,4.09C975.5,420.68,968.39,424.31,963.65,425.85Z"
        transform="translate(-117.18 -49.59)"
        fill="url(#dee71953-6e04-4c93-a054-139b0acb5af5)"
      />
      <path
        d="M965.39,759.25l3.37,10.11-11,11-36.24,8.43-26.13-2.53,1.69-9.27h0a11.18,11.18,0,0,1,8.3-8.17c10.74-2.52,31.22-8.28,35.52-16.27Z"
        transform="translate(-117.18 -49.59)"
        fill="#db8b8b"
      />
      <path
        d="M965.39,759.25l3.37,10.11-11,11-36.24,8.43-26.13-2.53,1.69-9.27h0a11.18,11.18,0,0,1,8.3-8.17c10.74-2.52,31.22-8.28,35.52-16.27Z"
        transform="translate(-117.18 -49.59)"
        opacity="0.1"
      />
      <path
        d="M903.86,783.69l5.06-6.74-5.06-7.81s-15.17,6.12-21.91,5.28-10.11,11,2.53,14.33,84.28,0,84.28,0,12.64-5.9,0-21.91l-11,8.43s-18.54,10.11-27.81,8.43Z"
        transform="translate(-117.18 -49.59)"
        fill="#2e3037"
      />
      <path
        d="M881.1,798l26.13,2.53,36.24-8.43,11-11-.89-2.68-1.26-3.76L951.06,771l-24.44-6.74a15.14,15.14,0,0,1-5,5c-7.94,5.43-22.2,9.35-30.57,11.31a8.77,8.77,0,0,0-1,.31,11.14,11.14,0,0,0-7.26,7.86Z"
        transform="translate(-117.18 -49.59)"
        fill="#db8b8b"
      />
      <path
        d="M881.1,798l26.13,2.53,36.24-8.43,11-11-.89-2.68-10.06,7.74s-18.54,10.11-27.81,8.43H889.53l5.06-6.74-4.54-7a11.14,11.14,0,0,0-7.26,7.86Z"
        transform="translate(-117.18 -49.59)"
        opacity="0.1"
      />
      <path
        d="M889.53,795.49l5.06-6.74-5.06-7.81s-15.17,6.12-21.91,5.28-10.11,11,2.53,14.33,84.28,0,84.28,0,12.64-5.9,0-21.91l-11,8.43s-18.54,10.11-27.81,8.43Z"
        transform="translate(-117.18 -49.59)"
        fill="#2e3037"
      />
      <path
        d="M910.6,306.66s40.46,4.21,34.56-12.64a30.34,30.34,0,0,1-1.25-14.88A41.25,41.25,0,0,1,951.06,262l-37.93,5.9a47.5,47.5,0,0,1,5.14,12.18C922.79,297.95,910.6,306.66,910.6,306.66Z"
        transform="translate(-117.18 -49.59)"
        fill="#db8b8b"
      />
      <path
        d="M979.71,498.82s-.84,36.24-9.27,53.94l-1.69,51.41s2.53,47.2,7.59,56.47-11,87.65-11,87.65v13.49s-27,10.11-34.56-1.69l7.59-100.3L935,520.73Z"
        transform="translate(-117.18 -49.59)"
        fill={fill2}
      />
      <path
        d="M979.71,498.82s-.84,36.24-9.27,53.94l-1.69,51.41s2.53,47.2,7.59,56.47-11,87.65-11,87.65v13.49s-27,10.11-34.56-1.69l7.59-100.3L935,520.73Z"
        transform="translate(-117.18 -49.59)"
        opacity="0.1"
      />
      <path
        d="M996.57,382.51l5.06,13.49,4.21,28.66a25.35,25.35,0,0,1-7.59,21.91c-10.11,10.11-23.6,31.18-23.6,31.18l-16-48s16-2.53,13.49-13.49S970.44,385,970.44,385Z"
        transform="translate(-117.18 -49.59)"
        fill={fill3}
      />
      <path
        d="M996.57,382.51l5.06,13.49,4.21,28.66a25.35,25.35,0,0,1-7.59,21.91c-10.11,10.11-23.6,31.18-23.6,31.18l-16-48s16-2.53,13.49-13.49S970.44,385,970.44,385Z"
        transform="translate(-117.18 -49.59)"
        opacity="0.05"
      />
      <path
        d="M921.66,769.27c10.81,17,27.64,7.37,30.62,5.45L951.06,771l-24.44-6.74A15.14,15.14,0,0,1,921.66,769.27Z"
        transform="translate(-117.18 -49.59)"
        opacity="0.1"
      />
      <path
        d="M887.85,531.69c.84,11.8,15.17,54.78,15.17,54.78l10.11,35.37a281.44,281.44,0,0,1,11.11,76.94c0,8.63.79,17.53,3.23,24.22,6.74,18.54-6.74,43.83-6.74,43.83,11.8,21.07,32,6.74,32,6.74V760.09s16-73.33,11.8-87.65-5.9-53.1-5.9-53.1v-70a79.38,79.38,0,0,0,23.13-50.32c.2-2.76.36-5.66.47-8.67.68-19-12.32-41.31-17.4-49.25-1.22-1.92-2-3-2-3l-74.42,29.52-.17.07,0,2.88,0,6.37Z"
        transform="translate(-117.18 -49.59)"
        fill={fill2}
      />
      <path
        d="M913.13,267.89a47.5,47.5,0,0,1,5.14,12.18,33,33,0,0,0,25.64-.94A41.25,41.25,0,0,1,951.06,262Z"
        transform="translate(-117.18 -49.59)"
        opacity="0.1"
      />
      <circle cx="812.81" cy="198.06" r="32.87" fill="#db8b8b" />
      <path
        d="M887.85,476.91l.36.07c35.34,7.27,79.71-29.57,79.71-29.57a43.68,43.68,0,0,1-3.07-6.27c-1.22-1.92-2-3-2-3l-74.42,29.52-.19,2.95Z"
        transform="translate(-117.18 -49.59)"
        opacity="0.1"
      />
      <path
        d="M897.12,305.81s47.2-28.66,62.37-16.86l21.07,27s13.49,4.21,11,31.18L1001.63,396l-30.34,7.59s-17.7,16.86-3.37,41.3c0,0-44.67,37.08-80.07,29.5l2.53-39.61s7.59-21.91-5.9-42.14-.84-39.61,11.8-47.2C896.28,345.43,902.17,313.4,897.12,305.81Z"
        transform="translate(-117.18 -49.59)"
        fill={fill3}
      />
      <path
        d="M894.59,477.75s-17.7,3.37-26.13,16.86a52.53,52.53,0,0,1-8.82,11,6.69,6.69,0,0,0,1,10.66c6.69,4,17.6,6.49,31.38-4.83C915.66,492.08,894.59,477.75,894.59,477.75Z"
        transform="translate(-117.18 -49.59)"
        fill="#db8b8b"
      />
      <path
        d="M920.72,321.83s16.86-1.69,21.07,42.14,11,53.94,11,53.94,4.21,22.76-5.9,36.24-29.5,42.14-29.5,42.14-21.07,5.9-25.28-15.17l26.13-48.88s4.21-16.86-5.9-28.66S894.59,313.4,920.72,321.83Z"
        transform="translate(-117.18 -49.59)"
        opacity="0.1"
      />
      <path
        d="M919,319.3s16.86-1.69,21.07,42.14,11,53.94,11,53.94,4.21,22.76-5.9,36.24-29.5,42.14-29.5,42.14-21.07,5.9-25.28-15.17l26.13-48.88s4.21-16.86-5.9-28.66S892.9,310.87,919,319.3Z"
        transform="translate(-117.18 -49.59)"
        fill={fill3}
      />
      <path
        d="M905.12,350.9S903.44,377,911,391.36s7.59,32.87,0,43"
        transform="translate(-117.18 -49.59)"
        opacity="0.1"
      />
      <path
        d="M900.91,318.88s6.74-6.74,26.13-7.59,27.81-6.74,27.81-6.74"
        transform="translate(-117.18 -49.59)"
        opacity="0.1"
      />
      <path
        d="M947.79,205.18c5.12.63,11,2.39,12.82,7.2.68,1.77.7,3.72,1.17,5.55,1.91,7.36,10.2,10.79,15.31,16.42,4.51,5,6.5,11.69,8,18.24a171.4,171.4,0,0,1,3,57.26c-1.64,14-5,27.74-5.94,41.78s.78,28.81,8.45,40.61c3.3,5.07,7.66,9.53,10,15.11-4.83,3.92-11.5,4.48-17.73,4.69q-10.87.37-21.75,0c-3.8-.13-7.77-.38-11.05-2.3a17.06,17.06,0,0,1-5.8-6.23c-4.15-7-5.19-15.5-5-23.66s1.47-16.28,1.62-24.45c.08-4.45-.27-9.18-2.83-12.82-1.85-2.63-4.66-4.4-7.06-6.53-9-8-12.12-20.82-12.42-32.87s1.69-24.12.67-36.13c-.4-4.7-2.16-10.35-6.78-11.26-1.46-.29-3,0-4.4-.56-3.71-1.43-3.35-6.66-4.41-10.49-1.19-4.3-4.86-7.62-5.89-12-2.43-10.2,10.55-22.37,19.06-25.92C925.48,203.2,938.67,204.06,947.79,205.18Z"
        transform="translate(-117.18 -49.59)"
        opacity="0.1"
      />
      <path
        d="M948.63,203.5c5.12.63,11,2.39,12.82,7.2.68,1.77.7,3.72,1.17,5.55,1.91,7.36,10.2,10.79,15.31,16.42,4.51,5,6.5,11.69,8,18.24a171.4,171.4,0,0,1,3,57.26c-1.64,14-5,27.74-5.94,41.78s.78,28.81,8.45,40.61c3.3,5.07,7.66,9.53,10,15.11-4.83,3.92-11.5,4.48-17.73,4.69q-10.87.37-21.75,0c-3.8-.13-7.77-.38-11.05-2.3a17.06,17.06,0,0,1-5.8-6.23c-4.15-7-5.19-15.5-5-23.66s1.47-16.28,1.62-24.45c.08-4.45-.27-9.18-2.83-12.82-1.85-2.63-4.66-4.4-7.06-6.53-9-8-12.12-20.82-12.42-32.87s1.69-24.12.67-36.13c-.4-4.7-2.16-10.35-6.78-11.26-1.46-.29-3,0-4.4-.56-3.71-1.43-3.35-6.66-4.41-10.49-1.19-4.3-4.86-7.62-5.89-12-2.43-10.2,10.55-22.37,19.06-25.92C926.32,201.51,939.51,202.37,948.63,203.5Z"
        transform="translate(-117.18 -49.59)"
        fill="#472727"
      />
    </svg>
  )
}

export function NewsSvg({
  fill1,
  fill2,
  fill3,
  fill4,
  width,
  height,
  className,
}) {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      id="a751cec9-7d0e-4c8a-a2d9-3a6c65a20454"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 799 667.07575"
    >
      <path
        d="M849.5,746.61987h-485l.21874-51.42174c.55348-130.11522,104.40844-237.32948,234.506-239.54236q2.10824-.03587,4.22346-.0359h.0004a245.33553,245.33553,0,0,1,85.75247,15.40589c92.054,34.28252,153.84089,121.38358,157.951,219.52807Z"
        transform="translate(-200.5 -116.46212)"
        fill={fill1}
      />
      <rect y="630.15774" width="799" height="2" fill={fill4} />
      <polygon
        points="398.287 400.165 419.657 415.708 441.028 598.33 491.54 598.33 474.055 390.451 404.115 363.252 398.287 400.165"
        fill={fill4}
      />
      <path
        d="M509.4184,458.34384s-5.82836,66.05475,17.48508,67.99754,112.68163-19.42787,112.68163-19.42787l42.74131,151.53736,64.112-33.02737s-38.85573-170.96523-62.16917-180.67916-101.02491-29.1418-101.02491-29.1418l-56.34082,9.71393Z"
        transform="translate(-200.5 -116.46212)"
        fill={fill4}
      />
      <path
        d="M649.29905,710.90611s-11.65673,44.68409-3.88558,48.56967a140.53483,140.53483,0,0,1,13.59951,7.77114v-7.77114s9.71393,9.71393,9.71393,17.48508,52.45524,9.71393,52.45524,0-1.94278-21.37066-11.65672-27.199-21.37065-38.85573-21.37065-38.85573Z"
        transform="translate(-200.5 -116.46212)"
        fill={fill4}
      />
      <polygon
        points="505.14 514.79 509.025 551.703 522.625 575.016 555.652 538.103 530.396 497.305 505.14 514.79"
        fill={fill4}
      />
      <path
        d="M736.72445,668.1648s7.15372-24.46025,14.26219-21.94406,34.30747,27.77242,34.30747,27.77242L804.722,685.64988s38.85573,3.88558,27.199,15.5423-54.398,27.199-73.82589,19.42786-23.31344-9.71393-23.31344-9.71393l-5.82836,3.88557-23.31344-5.82836,3.88557-40.79852S715.35379,685.64988,736.72445,668.1648Z"
        transform="translate(-200.5 -116.46212)"
        fill={fill4}
      />
      <circle cx="374.97315" cy="54.34929" r="44.68409" fill={fill3} />
      <path
        d="M547.30274,197.039s3.88558,40.79852-7.77114,40.79852S541.47438,288.35,572.559,288.35s42.74131-60.22639,42.74131-60.22639-21.37066-7.77115-17.48508-33.02737S547.30274,197.039,547.30274,197.039Z"
        transform="translate(-200.5 -116.46212)"
        fill={fill3}
      />
      <path
        d="M544.20687,234.72539l-16.332,6.99774L483.5501,260.47592a8.99955,8.99955,0,0,0-5.43656,9.29441l16.734,148.74639s-17.48508,36.91294,9.71394,38.85573L508.447,470.972l31.08459-36.913,116.5672-3.88557,1.94279-188.45031L604.70588,220.647S597.45181,260.755,544.20687,234.72539Z"
        transform="translate(-200.5 -116.46212)"
        fill={fill2}
      />
      <path
        d="M489.99054,266.008l-7.17945-2.05127a5.919,5.919,0,0,0-7.47123,4.7594L456.288,388.22347a41.5016,41.5016,0,0,0,4.05469,25.91676c5.8867,11.14588,18.11259,21.25444,43.24739,9.23345l17.48508-126.28113Z"
        transform="translate(-200.5 -116.46212)"
        fill={fill2}
      />
      <path
        d="M635.69954,238.809l23.31344,3.88557,64.112,89.36819L728.9533,347.605l36.913-11.65672s-1.94279,17.48508,17.48508,15.5423c0,0-23.31344,64.112-71.88311,44.68409s-64.112-77.71147-64.112-77.71147Z"
        transform="translate(-200.5 -116.46212)"
        fill={fill2}
      />
      <polygon
        points="462.399 161.203 374.973 126.232 371.088 137.889 411.886 172.859 462.399 161.203"
        fill="#ccc"
      />
      <path
        d="M548.56969,173.66c1.65033-.44064,1.89277-2.63175,1.94808-4.339.29622-9.14249,5.80151-18.52371,14.553-21.18523a24.46655,24.46655,0,0,1,10.313-.5675,39.06274,39.06274,0,0,1,13.96661,4.65951c2.27932,1.28145,4.51509,2.82482,7.10047,3.21626,1.70747.25852,9.10059,2.40806,10.80081,2.71057,3.7381.66508,7.2121,4.00959,10.79913,2.765,3.43173-1.19068,4.19139-5.634,4.27434-9.26547.189-8.27324-6.09157-19.72372-11.62353-25.87838-4.198-4.67054-10.519-6.82653-16.698-7.9476a111.168,111.168,0,0,0-21.83787-1.32849c-9.88858.17636-20.05125.93961-28.968,5.21828s-16.40657,12.79425-16.834,22.67516c-.089,2.05828.1233,4.12515-.04976,6.17808-.42218,5.00809-3.08153,9.5316-4.329,14.40017a26.35017,26.35017,0,0,0,3.67977,21.05747c3.30926,4.87005,16.54275,9.29112,16.35142,15.176,2.39183-2.478-3.24866-4.97242-.85683-7.45041a5.89248,5.89248,0,0,0,1.75244-7.19911l-2.53985-9.90672c-.46505-1.814-.92235-3.77752-.21992-5.51327C542.87485,164.40725,545.81807,174.39469,548.56969,173.66Z"
        transform="translate(-200.5 -116.46212)"
        fill={fill4}
      />
      <polygon
        points="371.088 137.889 334.175 281.655 477.941 332.168 561.481 262.227 613.936 104.862 528.453 167.031 371.088 137.889"
        fill="#f2f2f2"
      />
      <rect
        x="647.35642"
        y="216.98683"
        width="1.94246"
        height="144.66914"
        transform="translate(2.65912 725.90593) rotate(-75.21727)"
        fill="#e6e6e6"
      />
      <rect
        x="643.47085"
        y="226.70077"
        width="1.94246"
        height="144.66914"
        transform="translate(-9.62744 729.38435) rotate(-75.21727)"
        fill="#e6e6e6"
      />
      <rect
        x="641.52806"
        y="236.4147"
        width="1.94246"
        height="144.66914"
        transform="translate(-20.46692 734.74125) rotate(-75.21727)"
        fill="#e6e6e6"
      />
      <rect
        x="639.58528"
        y="246.12863"
        width="1.94246"
        height="144.66914"
        transform="translate(-31.3064 740.09815) rotate(-75.21727)"
        fill="#e6e6e6"
      />
      <rect
        x="637.64249"
        y="255.84257"
        width="1.94246"
        height="144.66914"
        transform="translate(-42.14588 745.45505) rotate(-75.21727)"
        fill="#e6e6e6"
      />
      <rect
        x="635.6997"
        y="265.5565"
        width="1.94246"
        height="144.66914"
        transform="translate(-52.98536 750.81195) rotate(-75.21727)"
        fill="#e6e6e6"
      />
      <rect
        x="633.75692"
        y="275.27043"
        width="1.94246"
        height="144.66914"
        transform="translate(-63.82485 756.16885) rotate(-75.21727)"
        fill="#e6e6e6"
      />
      <rect
        x="631.81413"
        y="284.98437"
        width="1.94246"
        height="144.66914"
        transform="translate(-74.66433 761.52575) rotate(-75.21727)"
        fill="#e6e6e6"
      />
      <rect
        x="629.87134"
        y="294.6983"
        width="1.94246"
        height="144.66914"
        transform="translate(-85.50381 766.88265) rotate(-75.21727)"
        fill="#e6e6e6"
      />
      <path
        d="M512.35043,360.10426s43.69485-26.09876,63.12272-8.61368-56.34081,42.7413-56.34081,42.7413Z"
        transform="translate(-200.5 -116.46212)"
        fill={fill3}
      />
      <path
        d="M464.73431,372.86123l56.34081-21.37065s-9.71393,36.91294,9.71394,38.85573L503.59,423.37368S449.192,423.37368,464.73431,372.86123Z"
        transform="translate(-200.5 -116.46212)"
        fill={fill2}
      />
      <polygon
        points="412.494 255.602 487.255 280.799 469.562 319.365 394.801 294.169 412.494 255.602"
        fill="#e6e6e6"
      />
      <polygon
        points="544.967 173.831 601.308 131.089 585.766 183.545 527.482 226.286 544.967 173.831"
        fill="#ccc"
      />
      <rect
        x="711.07913"
        y="339.83365"
        width="70.7185"
        height="1.9432"
        transform="translate(-254.72546 403.94235) rotate(-37.18317)"
        fill="#e6e6e6"
      />
      <rect
        x="707.19355"
        y="361.2043"
        width="70.7185"
        height="1.9432"
        transform="translate(-268.43105 405.93854) rotate(-37.18317)"
        fill="#e6e6e6"
      />
      <path
        d="M777.523,353.43336s48.56966-29.1418,17.48508-40.79852-31.08459,33.02738-31.08459,33.02738S761.98067,355.37615,777.523,353.43336Z"
        transform="translate(-200.5 -116.46212)"
        fill={fill3}
      />
      <polygon
        points="292.405 171.888 290.462 247.657 320.575 239.063 296.29 239.885 292.405 171.888"
        opacity="0.1"
      />
      <path
        d="M280.90863,712.35433c6.07317,22.45006,26.87512,36.35395,26.87512,36.35395s10.95541-22.49485,4.88225-44.94491-26.87512-36.354-26.87512-36.354S274.83547,689.90427,280.90863,712.35433Z"
        transform="translate(-200.5 -116.46212)"
        fill="#8985a8"
      />
      <path
        d="M289.83272,707.52957c16.66484,16.22256,18.70033,41.1604,18.70033,41.1604s-24.98356-1.36411-41.64841-17.58667-18.70033-41.1604-18.70033-41.1604S273.16787,691.307,289.83272,707.52957Z"
        transform="translate(-200.5 -116.46212)"
        fill={fill2}
      />
      <rect x="626" y="379.15774" width="30" height="30" fill="#f2f2f2" />
      <rect x="506" y="39.15774" width="30" height="30" fill="#f2f2f2" />
      <circle cx="159" cy="328.15774" r="15" fill="#f2f2f2" />
    </svg>
  )
}

export function LogoSvg({ fill1, fill2, fill3, width, height, className, id }) {
  return (
    <svg
      version="1.1"
      id={id}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 400 300"
    >
      <g>
        <polygon
          style={{ fill: fill1 }}
          points="38.7,206.5 55.8,233.8 102.5,154.1 70.6,154.1 	"
        />
        <polygon
          style={{ fill: fill1 }}
          points="34.1,179.1 29.6,188.3 7.9,155.2 19.3,155.2 	"
        />
        <polygon
          style={{ fill: fill1 }}
          points="72.3,89.7 55.2,62.3 8.5,142.1 40.4,142.1 	"
        />
        <polygon
          style={{ fill: fill1 }}
          points="76.9,117 81.4,107.9 103.1,140.9 91.7,140.9 	"
        />
        <g>
          <path
            style={{ fill: fill2 }}
            d="M166.9,96.9h-6.7l-1.7-9.4c-1.4-1.1-3.2-2.1-5.4-2.8c-2.3-0.8-4.9-1.1-7.8-1.1c-4.7,0-8.3,1-10.9,3
    c-2.5,2-3.8,4.6-3.8,7.8c0,2.9,1.3,5.2,3.9,7.1c2.6,1.9,6.7,3.6,12.5,5c6.9,1.7,12.2,4.2,15.7,7.6c3.6,3.3,5.4,7.5,5.4,12.4
    c0,5.3-2.2,9.5-6.5,12.7c-4.3,3.2-9.9,4.8-16.9,4.8c-5,0-9.5-0.8-13.4-2.5c-3.9-1.7-7.1-3.8-9.6-6.5v-12.2h6.7l1.7,9.4
    c1.7,1.4,3.8,2.6,6.1,3.5c2.4,0.9,5.2,1.4,8.5,1.4c4.5,0,8-0.9,10.6-2.8s3.9-4.5,3.9-7.7c0-3-1.1-5.5-3.4-7.5
    c-2.3-2-6.2-3.7-11.9-5.1c-6.9-1.7-12.4-4.1-16.3-7.3c-3.9-3.2-5.9-7.3-5.9-12.1c0-5.1,2.2-9.4,6.5-12.8c4.4-3.4,10-5.1,17-5.1
    c4.6,0,8.8,0.8,12.5,2.4c3.8,1.6,6.8,3.5,9.1,5.7V96.9z"
          />
          <path
            style={{ fill: fill2 }}
            d="M175.2,78.5v-5.7h16.2v40.7h5.7l10.4-12.9l-4.5-0.6v-5.7h20.4v5.7l-5.9,1.2l-12.8,15.3l15.6,19.8l5.5,1.1v5.7
    h-20.2v-5.7l4.5-0.6l-12.9-16.2h-5.8v15.5l7.3,1.3v5.7h-23.6v-5.7l7.3-1.3V79.8L175.2,78.5z"
          />
          <path
            style={{ fill: fill2 }}
            d="M264.3,143.1c-0.3-1.3-0.5-2.5-0.6-3.6c-0.2-1.1-0.3-2.1-0.3-3.2c-1.7,2.2-3.9,4-6.4,5.5
    c-2.6,1.5-5.3,2.2-8.2,2.2c-5.1,0-8.9-1.2-11.6-3.7s-4-6-4-10.5c0-4.6,1.9-8.2,5.6-10.7c3.7-2.5,8.9-3.8,15.4-3.8h9.1v-5.7
    c0-2.9-0.9-5.1-2.7-6.8c-1.8-1.7-4.3-2.5-7.5-2.5c-2.2,0-4.2,0.3-5.9,0.9c-1.7,0.6-3.1,1.4-4.1,2.3l-0.9,5.1h-6.8v-9.5
    c2.2-1.8,4.9-3.2,8.1-4.3c3.2-1.1,6.6-1.6,10.3-1.6c5.5,0,10,1.4,13.4,4.2c3.4,2.8,5.1,6.9,5.1,12.1v23.5c0,0.6,0,1.2,0,1.8
    c0,0.6,0.1,1.2,0.1,1.8l3.6,0.5v5.7H264.3z M250,136.6c2.9,0,5.6-0.7,8.1-2.1c2.4-1.4,4.2-3.1,5.2-5.1v-8h-9.5
    c-3.6,0-6.5,0.8-8.6,2.5c-2.1,1.7-3.2,3.7-3.2,6.1c0,2.1,0.7,3.8,2,4.9C245.3,136,247.3,136.6,250,136.6z"
          />
          <path
            style={{ fill: fill2 }}
            d="M282.2,156.1l7.3-1.3v-53.5l-7.3-1.3v-5.7h15l0.8,5.9c1.6-2.2,3.6-3.9,5.9-5.1c2.3-1.2,5-1.8,8.1-1.8
    c6,0,10.7,2.4,14.1,7.2s5,11.1,5,19v0.9c0,7-1.7,12.7-5,17c-3.4,4.3-8,6.5-13.9,6.5c-3,0-5.6-0.5-7.9-1.5c-2.3-1-4.2-2.5-5.8-4.5
    v16.8l7.3,1.3v5.7h-23.6V156.1z M322.2,119.6c0-5.6-1.1-10.1-3.2-13.6c-2.2-3.5-5.4-5.3-9.6-5.3c-2.5,0-4.7,0.6-6.5,1.7
    s-3.3,2.7-4.4,4.7v23.7c1.1,2,2.6,3.6,4.3,4.7c1.8,1.1,4,1.6,6.6,1.6c4.2,0,7.4-1.5,9.5-4.6c2.2-3.1,3.2-7,3.2-11.9V119.6z"
          />
          <path
            style={{ fill: fill2 }}
            d="M361.4,144c-6.8,0-12.2-2.3-16.2-6.8c-4-4.5-6-10.4-6-17.6v-2c0-6.9,2.1-12.7,6.2-17.3
    c4.1-4.6,9-6.9,14.7-6.9c6.5,0,11.5,2,14.9,5.9c3.4,4,5.1,9.2,5.1,15.9v5.5h-31.6l-0.1,0.2c0.1,4.7,1.3,8.5,3.6,11.6
    c2.3,3,5.5,4.5,9.6,4.5c3,0,5.6-0.4,7.9-1.3c2.3-0.9,4.2-2,5.9-3.5l3.5,5.8c-1.7,1.7-4,3.1-6.9,4.3
    C368.9,143.5,365.4,144,361.4,144z M360,100.4c-3,0-5.5,1.3-7.6,3.8s-3.4,5.7-3.9,9.4l0.1,0.2h22.5v-1.2c0-3.5-0.9-6.4-2.8-8.7
    C366.5,101.6,363.7,100.4,360,100.4z"
          />
        </g>
        <g>
          <path
            style={{ fill: fill3 }}
            d="M127.6,158.5v-1.7h6.9v1.7l-2.1,0.4v6.6h9.3v-6.6l-2.1-0.4v-1.7h2.1h2.6h2.1v1.7l-2.1,0.4V174l2.1,0.4v1.7
    h-6.9v-1.7l2.1-0.4v-6.4h-9.3v6.4l2.1,0.4v1.7h-6.9v-1.7l2.1-0.4v-15.1L127.6,158.5z"
          />
          <path
            style={{ fill: fill3 }}
            d="M148.6,168.8c0-2.1,0.6-3.8,1.7-5.2c1.1-1.4,2.7-2,4.6-2c0.5,0,0.9,0,1.4,0.1c0.4,0.1,0.9,0.2,1.3,0.4l1-1.9
    h1.7l-1.4,2.8c0.8,0.6,1.4,1.5,1.8,2.5c0.4,1,0.6,2.1,0.6,3.4v0.3c0,2.1-0.6,3.9-1.7,5.2c-1.1,1.3-2.7,2-4.6,2
    c-0.5,0-0.9,0-1.3-0.1c-0.4-0.1-0.8-0.2-1.2-0.3l-1,1.9h-1.7l1.3-2.7c-0.8-0.6-1.5-1.5-1.9-2.5c-0.4-1-0.7-2.2-0.7-3.5V168.8z
     M151.2,169.1c0,0.8,0.1,1.5,0.3,2.2c0.2,0.7,0.4,1.2,0.8,1.6h0.1l4.4-8.8c-0.2-0.1-0.5-0.2-0.8-0.3c-0.3-0.1-0.6-0.1-0.9-0.1
    c-1.2,0-2.2,0.5-2.8,1.5c-0.6,1-0.9,2.2-0.9,3.7V169.1z M158.7,168.8c0-0.7-0.1-1.4-0.2-2c-0.2-0.6-0.4-1.1-0.7-1.6h-0.1l-4.3,8.7
    c0.2,0.1,0.5,0.2,0.7,0.2s0.5,0.1,0.8,0.1c1.2,0,2.2-0.5,2.8-1.5c0.6-1,1-2.2,1-3.7V168.8z"
          />
          <path
            style={{ fill: fill3 }}
            d="M163.7,169.2c0-2.3,0.5-4.1,1.5-5.5c1-1.4,2.4-2.1,4.1-2.1c0.9,0,1.7,0.2,2.4,0.5c0.7,0.4,1.2,0.9,1.7,1.5
    l0.2-1.8h2.2v14c0,1.8-0.5,3.3-1.5,4.3c-1,1.1-2.5,1.6-4.4,1.6c-0.7,0-1.4-0.1-2.2-0.3c-0.8-0.2-1.5-0.4-2.1-0.8l0.7-2
    c0.5,0.3,1.1,0.5,1.7,0.6c0.7,0.2,1.3,0.2,1.9,0.2c1.2,0,2-0.3,2.5-1c0.5-0.6,0.8-1.6,0.8-2.8v-1.4c-0.5,0.6-1,1-1.7,1.4
    c-0.6,0.3-1.4,0.5-2.2,0.5c-1.7,0-3.1-0.6-4.1-1.9c-1-1.3-1.5-2.9-1.5-5V169.2z M166.2,169.4c0,1.4,0.3,2.6,0.9,3.4
    c0.6,0.9,1.5,1.3,2.8,1.3c0.8,0,1.4-0.2,2-0.5s1-0.9,1.3-1.5v-6.5c-0.3-0.6-0.8-1.1-1.3-1.4c-0.5-0.4-1.2-0.5-1.9-0.5
    c-1.2,0-2.2,0.5-2.8,1.6c-0.6,1-0.9,2.4-0.9,4V169.4z"
          />
          <path
            style={{ fill: fill3 }}
            d="M189.8,166.3h-1.9l-0.4-1.7c-0.3-0.3-0.7-0.5-1.2-0.7c-0.5-0.2-1-0.3-1.6-0.3c-0.9,0-1.6,0.2-2,0.6
    c-0.4,0.4-0.7,0.9-0.7,1.5c0,0.6,0.2,1,0.6,1.3c0.4,0.3,1.2,0.6,2.4,0.9c1.8,0.4,3.1,0.9,4,1.6c0.9,0.7,1.3,1.5,1.3,2.7
    c0,1.3-0.5,2.3-1.5,3.1c-1,0.8-2.4,1.2-4,1.2c-1.1,0-2-0.1-2.9-0.4c-0.9-0.2-1.6-0.6-2.3-1.1l0-3.2h1.9l0.5,1.9
    c0.3,0.3,0.7,0.5,1.2,0.6c0.5,0.1,1,0.2,1.6,0.2c0.9,0,1.6-0.2,2.2-0.6c0.5-0.4,0.8-0.9,0.8-1.5c0-0.6-0.2-1-0.7-1.4
    c-0.4-0.4-1.3-0.7-2.5-1c-1.7-0.4-3-0.9-3.9-1.5c-0.9-0.6-1.3-1.5-1.3-2.6c0-1.2,0.5-2.1,1.5-3s2.3-1.2,3.8-1.2
    c1.1,0,2,0.1,2.9,0.4c0.9,0.3,1.6,0.7,2.1,1.2L189.8,166.3z"
          />
          <path
            style={{ fill: fill3 }}
            d="M192.2,157.2v-1.7h4.7v11.9h1.7l3-3.8l-1.3-0.2v-1.7h6v1.7l-1.7,0.3l-3.7,4.5l4.6,5.8l1.6,0.3v1.7h-5.9v-1.7
    l1.3-0.2l-3.8-4.7H197v4.5l2.1,0.4v1.7h-6.9v-1.7l2.1-0.4v-16.4L192.2,157.2z"
          />
          <path
            style={{ fill: fill3 }}
            d="M219.1,173.9c-0.4,0.8-1,1.3-1.7,1.8c-0.7,0.4-1.5,0.6-2.4,0.6c-1.5,0-2.7-0.5-3.6-1.5
    c-0.9-1-1.3-2.5-1.3-4.6v-6.3l-1.6-0.4v-1.7h1.6h2.6v8.4c0,1.5,0.2,2.6,0.7,3.1c0.4,0.6,1.1,0.9,2.1,0.9c0.9,0,1.7-0.2,2.3-0.6
    c0.6-0.4,1.1-0.9,1.4-1.6v-8.2l-1.9-0.4v-1.7h1.9h2.6V174l1.6,0.4v1.7h-4L219.1,173.9z"
          />
          <path
            style={{ fill: fill3 }}
            d="M224.9,157.2v-1.7h4.7V174l2.1,0.4v1.7h-6.9v-1.7l2.1-0.4v-16.4L224.9,157.2z"
          />
          <path
            style={{ fill: fill3 }}
            d="M240.3,176.3c-2,0-3.6-0.7-4.7-2c-1.2-1.3-1.8-3-1.8-5.1v-0.6c0-2,0.6-3.7,1.8-5.1c1.2-1.3,2.6-2,4.3-2
    c1.9,0,3.4,0.6,4.3,1.7c1,1.2,1.5,2.7,1.5,4.6v1.6h-9.2l0,0.1c0,1.4,0.4,2.5,1,3.4c0.7,0.9,1.6,1.3,2.8,1.3c0.9,0,1.6-0.1,2.3-0.4
    s1.2-0.6,1.7-1l1,1.7c-0.5,0.5-1.2,0.9-2,1.2C242.4,176.1,241.4,176.3,240.3,176.3z M239.9,163.6c-0.9,0-1.6,0.4-2.2,1.1
    c-0.6,0.7-1,1.6-1.1,2.8l0,0.1h6.6v-0.3c0-1-0.3-1.9-0.8-2.5C241.7,163.9,240.9,163.6,239.9,163.6z"
          />
          <path
            style={{ fill: fill3 }}
            d="M247.9,174.3l2.1-0.4v-10.2l-2.1-0.4v-1.7h4.5l0.2,2.1c0.5-0.8,1.1-1.3,1.8-1.8c0.7-0.4,1.5-0.6,2.4-0.6
    c1.5,0,2.7,0.4,3.6,1.3c0.8,0.9,1.3,2.3,1.3,4.2v7l2.1,0.4v1.7h-6.9v-1.7l2.1-0.4v-6.9c0-1.3-0.2-2.1-0.7-2.7
    c-0.5-0.5-1.3-0.8-2.3-0.8c-0.7,0-1.4,0.2-2,0.5c-0.6,0.4-1,0.9-1.4,1.5v8.3l2.1,0.4v1.7h-6.9V174.3z"
          />
          <path
            style={{ fill: fill3 }}
            d="M127.6,212.1l2.1-0.4v-15.6l-2.1-0.4v-1.7h4.4l0.2,1.7c0.5-0.6,1-1.1,1.7-1.5s1.5-0.5,2.4-0.5
    c1.8,0,3.1,0.7,4.1,2.1c1,1.4,1.5,3.2,1.5,5.5v0.3c0,2-0.5,3.7-1.5,5c-1,1.3-2.3,1.9-4.1,1.9c-0.9,0-1.6-0.1-2.3-0.4
    c-0.7-0.3-1.2-0.7-1.7-1.3v4.9l2.1,0.4v1.7h-6.9V212.1z M139.2,201.4c0-1.6-0.3-2.9-0.9-4c-0.6-1-1.6-1.6-2.8-1.6
    c-0.7,0-1.4,0.2-1.9,0.5c-0.5,0.3-1,0.8-1.3,1.4v6.9c0.3,0.6,0.7,1,1.3,1.4c0.5,0.3,1.2,0.5,1.9,0.5c1.2,0,2.2-0.4,2.8-1.3
    c0.6-0.9,0.9-2.1,0.9-3.5V201.4z"
          />
          <path
            style={{ fill: fill3 }}
            d="M153.5,208.3c-0.1-0.4-0.1-0.7-0.2-1c0-0.3-0.1-0.6-0.1-0.9c-0.5,0.6-1.1,1.2-1.9,1.6
    c-0.7,0.4-1.5,0.6-2.4,0.6c-1.5,0-2.6-0.4-3.4-1.1c-0.8-0.7-1.2-1.7-1.2-3.1c0-1.3,0.5-2.4,1.6-3.1c1.1-0.7,2.6-1.1,4.5-1.1h2.7
    v-1.7c0-0.8-0.3-1.5-0.8-2c-0.5-0.5-1.3-0.7-2.2-0.7c-0.6,0-1.2,0.1-1.7,0.2c-0.5,0.2-0.9,0.4-1.2,0.7l-0.3,1.5h-2v-2.8
    c0.6-0.5,1.4-0.9,2.4-1.2c0.9-0.3,1.9-0.5,3-0.5c1.6,0,2.9,0.4,3.9,1.2c1,0.8,1.5,2,1.5,3.5v6.8c0,0.2,0,0.4,0,0.5
    c0,0.2,0,0.4,0,0.5l1.1,0.1v1.7H153.5z M149.3,206.4c0.9,0,1.6-0.2,2.4-0.6c0.7-0.4,1.2-0.9,1.5-1.5V202h-2.8
    c-1.1,0-1.9,0.2-2.5,0.7c-0.6,0.5-0.9,1.1-0.9,1.8c0,0.6,0.2,1.1,0.6,1.4S148.5,206.4,149.3,206.4z M147.6,189.8
    c0-0.7,0.3-1.4,0.8-1.9c0.5-0.5,1.2-0.7,1.9-0.7c0.7,0,1.4,0.2,1.9,0.7c0.5,0.5,0.8,1.1,0.8,1.9c0,0.8-0.3,1.4-0.8,1.8
    s-1.2,0.7-1.9,0.7c-0.8,0-1.4-0.2-1.9-0.7C147.9,191.1,147.6,190.5,147.6,189.8z M149,189.8c0,0.4,0.1,0.7,0.4,1
    c0.3,0.3,0.6,0.4,1,0.4c0.4,0,0.7-0.1,0.9-0.4c0.3-0.3,0.4-0.6,0.4-1c0-0.4-0.1-0.7-0.4-1c-0.3-0.3-0.6-0.4-0.9-0.4
    c-0.4,0-0.7,0.1-1,0.4C149.1,189,149,189.4,149,189.8z"
          />
          <path
            style={{ fill: fill3 }}
            d="M171.2,190.8l-1.7,0.3l4.3,12.4l0.4,1.5h0.1l0.4-1.5l4.3-12.4l-1.7-0.3v-1.7h6v1.7l-1.5,0.2l-6.4,17.3h-2.2
    l-6.4-17.3l-1.5-0.2v-1.7h6V190.8z"
          />
          <path
            style={{ fill: fill3 }}
            d="M190.9,208.6c-2,0-3.6-0.7-4.7-2c-1.2-1.3-1.8-3-1.8-5.1v-0.6c0-2,0.6-3.7,1.8-5.1c1.2-1.3,2.6-2,4.3-2
    c1.9,0,3.4,0.6,4.3,1.7c1,1.2,1.5,2.7,1.5,4.6v1.6h-9.2l0,0.1c0,1.4,0.4,2.5,1,3.4c0.7,0.9,1.6,1.3,2.8,1.3c0.9,0,1.6-0.1,2.3-0.4
    s1.2-0.6,1.7-1l1,1.7c-0.5,0.5-1.2,0.9-2,1.2C193.1,208.4,192.1,208.6,190.9,208.6z M190.5,195.8c-0.9,0-1.6,0.4-2.2,1.1
    c-0.6,0.7-1,1.6-1.1,2.8l0,0.1h6.6v-0.3c0-1-0.3-1.9-0.8-2.5C192.4,196.2,191.6,195.8,190.5,195.8z"
          />
          <path
            style={{ fill: fill3 }}
            d="M209.8,198.5h-1.9l-0.4-1.7c-0.3-0.3-0.7-0.5-1.2-0.7c-0.5-0.2-1-0.3-1.6-0.3c-0.9,0-1.6,0.2-2,0.6
    c-0.4,0.4-0.7,0.9-0.7,1.5c0,0.6,0.2,1,0.6,1.3c0.4,0.3,1.2,0.6,2.4,0.9c1.8,0.4,3.1,0.9,4,1.6c0.9,0.7,1.3,1.5,1.3,2.7
    c0,1.3-0.5,2.3-1.5,3.1c-1,0.8-2.4,1.2-4,1.2c-1.1,0-2-0.1-2.9-0.4s-1.6-0.6-2.3-1.1l0-3.2h1.9l0.5,1.9c0.3,0.3,0.7,0.5,1.2,0.6
    c0.5,0.1,1,0.2,1.6,0.2c0.9,0,1.6-0.2,2.2-0.6c0.5-0.4,0.8-0.9,0.8-1.5c0-0.6-0.2-1-0.7-1.4c-0.4-0.4-1.3-0.7-2.5-1
    c-1.7-0.4-3-0.9-3.9-1.5c-0.9-0.6-1.3-1.5-1.3-2.6c0-1.2,0.5-2.1,1.5-3c1-0.8,2.3-1.2,3.8-1.2c1.1,0,2,0.1,2.9,0.4
    c0.9,0.3,1.6,0.7,2.1,1.2L209.8,198.5z"
          />
          <path
            style={{ fill: fill3 }}
            d="M217.1,190.6v3.4h2.7v1.9h-2.7v8.6c0,0.7,0.1,1.1,0.4,1.4c0.3,0.3,0.6,0.4,1.1,0.4c0.2,0,0.5,0,0.8-0.1
    c0.3,0,0.5-0.1,0.7-0.1l0.4,1.8c-0.2,0.2-0.6,0.3-1.1,0.4c-0.5,0.1-0.9,0.2-1.4,0.2c-1.1,0-1.9-0.3-2.5-1c-0.6-0.6-0.9-1.6-0.9-3
    V196h-2.2v-1.9h2.2v-3.4H217.1z"
          />
          <path
            style={{ fill: fill3 }}
            d="M222.1,189.5v-1.7h4.7v18.5l2.1,0.4v1.7h-6.9v-1.7l2.1-0.4v-16.4L222.1,189.5z"
          />
          <path
            style={{ fill: fill3 }}
            d="M240.2,208.3c-0.1-0.4-0.1-0.7-0.2-1c0-0.3-0.1-0.6-0.1-0.9c-0.5,0.6-1.1,1.2-1.9,1.6
    c-0.7,0.4-1.5,0.6-2.4,0.6c-1.5,0-2.6-0.4-3.4-1.1c-0.8-0.7-1.2-1.7-1.2-3.1c0-1.3,0.5-2.4,1.6-3.1c1.1-0.7,2.6-1.1,4.5-1.1h2.7
    v-1.7c0-0.8-0.3-1.5-0.8-2c-0.5-0.5-1.3-0.7-2.2-0.7c-0.6,0-1.2,0.1-1.7,0.2c-0.5,0.2-0.9,0.4-1.2,0.7l-0.3,1.5h-2v-2.8
    c0.6-0.5,1.4-0.9,2.4-1.2c0.9-0.3,1.9-0.5,3-0.5c1.6,0,2.9,0.4,3.9,1.2c1,0.8,1.5,2,1.5,3.5v6.8c0,0.2,0,0.4,0,0.5
    c0,0.2,0,0.4,0,0.5l1.1,0.1v1.7H240.2z M236,206.4c0.9,0,1.6-0.2,2.4-0.6c0.7-0.4,1.2-0.9,1.5-1.5V202h-2.8
    c-1.1,0-1.9,0.2-2.5,0.7c-0.6,0.5-0.9,1.1-0.9,1.8c0,0.6,0.2,1.1,0.6,1.4S235.3,206.4,236,206.4z"
          />
          <path
            style={{ fill: fill3 }}
            d="M245.4,206.6l2.1-0.4v-10.2l-2.1-0.4v-1.7h4.5l0.2,2.1c0.5-0.8,1.1-1.3,1.8-1.8c0.7-0.4,1.5-0.6,2.4-0.6
    c1.5,0,2.7,0.4,3.6,1.3c0.8,0.9,1.3,2.3,1.3,4.2v7l2.1,0.4v1.7h-6.9v-1.7l2.1-0.4v-6.9c0-1.3-0.2-2.1-0.7-2.7
    c-0.5-0.5-1.3-0.8-2.3-0.8c-0.7,0-1.4,0.2-2,0.5c-0.6,0.4-1,0.9-1.4,1.5v8.3l2.1,0.4v1.7h-6.9V206.6z"
          />
          <path
            style={{ fill: fill3 }}
            d="M272.5,206.6c-0.5,0.6-1,1.1-1.7,1.5c-0.7,0.3-1.4,0.5-2.3,0.5c-1.7,0-3.1-0.6-4.1-1.9c-1-1.3-1.5-2.9-1.5-5
    v-0.3c0-2.3,0.5-4.1,1.5-5.5c1-1.4,2.3-2.1,4.1-2.1c0.8,0,1.6,0.2,2.2,0.5c0.6,0.3,1.2,0.8,1.6,1.3v-5.8l-2.1-0.4v-1.7h2.1h2.6
    v18.5l2.1,0.4v1.7h-4.4L272.5,206.6z M265.5,201.7c0,1.4,0.3,2.6,0.9,3.4s1.5,1.3,2.7,1.3c0.8,0,1.4-0.2,1.9-0.5
    c0.5-0.3,1-0.8,1.3-1.5v-6.6c-0.3-0.6-0.8-1.1-1.3-1.4c-0.5-0.3-1.2-0.5-1.9-0.5c-1.2,0-2.1,0.5-2.7,1.5s-0.9,2.4-0.9,4V201.7z"
          />
          <path
            style={{ fill: fill3 }}
            d="M285.6,208.6c-2,0-3.6-0.7-4.7-2c-1.2-1.3-1.8-3-1.8-5.1v-0.6c0-2,0.6-3.7,1.8-5.1c1.2-1.3,2.6-2,4.3-2
    c1.9,0,3.4,0.6,4.3,1.7c1,1.2,1.5,2.7,1.5,4.6v1.6h-9.2l0,0.1c0,1.4,0.4,2.5,1,3.4c0.7,0.9,1.6,1.3,2.8,1.3c0.9,0,1.6-0.1,2.3-0.4
    c0.7-0.2,1.2-0.6,1.7-1l1,1.7c-0.5,0.5-1.2,0.9-2,1.2C287.8,208.4,286.7,208.6,285.6,208.6z M285.2,195.8c-0.9,0-1.6,0.4-2.2,1.1
    c-0.6,0.7-1,1.6-1.1,2.8l0,0.1h6.6v-0.3c0-1-0.3-1.9-0.8-2.5C287.1,196.2,286.2,195.8,285.2,195.8z"
          />
          <path
            style={{ fill: fill3 }}
            d="M298.1,190.6v3.4h2.7v1.9h-2.7v8.6c0,0.7,0.1,1.1,0.4,1.4c0.3,0.3,0.6,0.4,1.1,0.4c0.2,0,0.5,0,0.8-0.1
    c0.3,0,0.5-0.1,0.7-0.1l0.4,1.8c-0.2,0.2-0.6,0.3-1.1,0.4c-0.5,0.1-0.9,0.2-1.4,0.2c-1.1,0-1.9-0.3-2.5-1c-0.6-0.6-0.9-1.6-0.9-3
    V196h-2.2v-1.9h2.2v-3.4H298.1z"
          />
        </g>
      </g>
    </svg>
  )
}

export function Map({
  width,
  height,
  fill,
  fill2,
  onHover,
  hovered,
  hoverColor,
  onClick,
}) {
  return (
    <svg version="1.1" viewBox="0 0 892 722.4" width={width} height={height}>
      <g>
        <polygon
          style={{ fill: fill }}
          points="120.9,308.2 116.3,311.7 111.8,317.3 108.3,319.7 105.4,320.5 105.4,320.6 108.4,319.7 111.9,317.3 116.4,311.7 
     121,308.2 123.3,308.1 123.3,308.1 	"
        />
        <polygon
          style={{ fill: fill }}
          points="98.7,319.5 94.5,322.7 94.6,322.7 98.8,319.5 104.2,317.2 104.1,317.2 	"
        />
        <polygon
          style={{ fill: fill }}
          points="133.4,308.5 129.9,312.6 130,312.6 133.5,308.5 139.8,306.4 139.7,306.4 	"
        />
        <polygon
          style={{ fill: fill }}
          points="310.4,71 310.4,71.1 311.3,69.4 311.3,69.3 	"
        />
        <polygon
          style={{ fill: fill }}
          points="316.4,94 316.4,94.1 317.4,76.6 317.4,76.6 	"
        />
        <polygon
          style={{ fill: fill }}
          points="326.8,129.5 326.8,129.4 326.8,129.4 	"
        />
        <polygon
          style={{ fill: fill }}
          points="153.1,303.7 149.3,313.4 149.4,313.4 153.2,303.7 155.1,301.3 155,301.3 	"
        />
        <polygon
          style={{ fill: fill }}
          points="89.5,635.9 87.6,638.3 84.4,640.3 84.6,640.6 87.8,638.4 89.7,636 90.5,636.1 90.5,635.9 	"
        />
        <polygon
          style={{ fill: fill }}
          points="235.1,313.1 235.1,318.9 228.5,321.7 225.6,325.8 222.3,327.3 222.3,327.4 225.7,325.8 228.6,321.7 235.2,318.9 
     235.2,313.1 237.5,312.6 237.5,312.6 	"
        />
        <polygon
          style={{ fill: fill }}
          points="113.4,628.2 115.7,627.9 113.2,628.1 	"
        />
        <polygon
          style={{ fill: fill }}
          points="195.4,330.3 195.4,330.3 205.1,319.2 205,319.2 	"
        />
        <polygon
          style={{ fill: fill }}
          points="214.1,315.7 211,319.6 209.3,320.1 209.3,320.1 211.1,319.6 214.2,315.7 216.6,314.8 216.6,314.7 	"
        />
        <polygon
          style={{ fill: fill }}
          points="157,301.3 157.1,301.3 165.4,295.6 165.4,295.6 	"
        />
        <polygon
          style={{ fill: fill }}
          points="217.2,69.4 217.3,69.4 217.7,64.6 217.6,64.7 	"
        />
        <polygon
          style={{ fill: fill }}
          points="294.5,68.1 293.1,69.5 293.2,69.5 294.8,67.9 294.9,65.7 294.6,65.8 	"
        />
        <polygon
          style={{ fill: fill }}
          points="203.9,77.4 206.4,74.9 207,72.4 206.2,75 	"
        />
        <polygon
          style={{ fill: fill }}
          points="176.6,79.5 176.7,79.5 177.1,76.3 176.9,76.4 	"
        />
        <polygon
          style={{ fill: fill }}
          points="188.2,75.6 188.4,75.6 191.2,72.1 191.1,72 	"
        />
        <polygon
          style={{ fill: fill }}
          points="102.7,75.8 101.8,79.2 102,79 102.7,75.9 106,73.2 106.2,72.8 106,73.1 	"
        />
        <polygon
          style={{ fill: fill }}
          points="181.2,74.1 181.3,74.1 182.1,70.7 182.1,70.7 	"
        />
        <polygon style={{ fill: fill }} points="264.9,77 266,76.3 266,76.3 	" />
        <polygon
          style={{ fill: fill }}
          points="208.7,69.7 208.9,69.5 208.9,69.5 	"
        />
        <polygon
          style={{ fill: fill }}
          points="278.4,74.7 277.1,76.8 277.2,76.8 278.7,74.5 282,70.2 281.7,70.3 	"
        />
        <polygon
          style={{ fill: fill }}
          points="231.4,60.5 231.6,60.4 234.7,56 234.6,56 	"
        />
        <polygon
          style={{ fill: fill }}
          points="225.7,59.8 226.8,59.1 226.8,59 	"
        />
        <polygon
          style={{ fill: fill }}
          points="121.1,619.9 122.8,623.8 121.6,625.2 117.3,627.8 117.5,627.7 121.8,625.1 123.1,623.7 121.3,619.9 125,616.4 
     124.8,616.3 	"
        />
        <polygon
          style={{ fill: fill }}
          points="250,320.3 250,320.3 245.1,323.2 245.1,323.2 	"
        />
        <polygon
          style={{ fill: fill }}
          points="368.7,320.6 358.5,329.3 351.1,342.1 349.9,345 349.9,345.1 351.1,342.2 358.5,329.4 368.7,320.7 380.8,326.3 
     384.3,326.9 380.8,326.2 	"
        />
        <polygon
          style={{ fill: fill }}
          points="349.8,406.8 351.8,408.5 346.4,439 345.7,458.4 338.7,469.5 335.6,485.5 325.3,503.6 320,509.6 318.6,516.1 
     318.7,519.7 317.8,521.3 313.7,524.4 307.2,524.7 302,526 295.3,532.3 287.8,546.8 290.2,547.4 288.3,552.8 289.8,554.6 
     287.8,557.7 289.7,563.5 288,565.9 288.1,567.5 282.8,574.3 268.7,577 254.3,585.3 245.8,591.3 238.1,593.4 238.2,593.4 
     246.2,591.4 254.9,585.3 269.2,577.1 283.3,574.4 285.1,580.3 284.7,591.9 284.9,592.1 285.2,580.4 283.3,574.3 288.5,567.5 
     288.4,565.9 290.1,563.5 288.2,557.7 290.4,554.6 288.9,552.8 290.7,547.4 288.2,546.8 295.8,532.3 302.4,526 307.6,524.7 
     314.1,524.5 318.2,521.3 319.2,519.7 318.9,516 320.3,509.6 325.6,503.6 325.7,503.5 335.9,485.6 339,469.6 346.2,458.5 
     346.7,439.1 352.1,408.6 350.1,406.9 	"
        />
        <polygon
          style={{ fill: fill }}
          points="290.8,372.2 286.6,372.6 282.4,368 279.4,368.3 270.3,366.8 270.5,361.9 267,338.8 257.5,335.8 254.8,336.6 
     250.7,339.2 241.5,338.7 241.1,337.7 246.1,330.2 246,330.2 240.9,337.9 241.3,338.9 250.5,339.4 254.5,336.8 257.3,336 266.8,339 
     270.3,362.1 270.1,367 279.2,368.6 282.2,368.2 286.4,372.8 290.6,372.4 	"
        />
        <polygon
          style={{ fill: fill }}
          points="252.4,326.9 252.9,324.9 252.3,323.4 252.6,325.1 252.2,327.1 	"
        />
        <polygon
          style={{ fill: fill }}
          points="151.4,611.5 146.1,617 144.2,620.2 144.4,620.2 146.4,617 151.7,611.5 153.8,611.3 153.6,611.2 	"
        />
        <polygon
          style={{ fill: fill }}
          points="244.4,317.7 240.8,318.3 240.8,318.3 244.4,317.7 	"
        />
        <polygon
          style={{ fill: fill }}
          points="409.7,281.3 405.3,287.8 405.3,287.8 409.7,281.4 421.1,290.7 	"
        />
        <polygon
          style={{ fill: fill }}
          points="396.7,243 396.7,243 391.6,246 	"
        />
        <polygon
          style={{ fill: fill }}
          points="400.6,305.3 401,305 404.7,303.6 404.8,303.6 401.1,304.9 	"
        />
        <polygon
          style={{ fill: fill }}
          points="406.3,233.1 406.3,231.4 407.6,231.4 409.5,227.5 409.5,227.5 407.6,231.3 406.3,231.3 406.3,233 401.5,233.9 
     400.5,234.9 397,239.6 397,239.7 400.5,235 401.5,234 	"
        />
        <polygon
          style={{ fill: fill }}
          points="212.7,567.7 198.3,575.2 197.8,578.1 198.2,578.1 198.7,575.1 213.1,567.6 221.8,561.4 221.6,561.3 	"
        />
        <polygon
          style={{ fill: fill }}
          points="231.8,562 228.8,562.5 232.2,562 234.2,560.9 233.9,560.8 	"
        />
        <polygon
          style={{ fill: fill }}
          points="190.6,586.9 191.3,590 190.7,591.5 185.7,596.6 184.3,601.7 181.8,606.4 181.5,608.3 182.6,611.2 181.7,616 
     179.2,616.5 179.4,616.5 182,616 183.1,611.2 181.9,608.3 182.2,606.4 184.9,601.6 186.1,596.5 191.2,591.4 191.8,589.9 
     191.1,586.6 194.3,578.6 193.9,578.6 	"
        />
        <polygon
          style={{ fill: fill }}
          points="158.5,617.2 158.5,617.3 168.6,613.8 168.4,613.8 	"
        />
        <polygon
          style={{ fill: fill }}
          points="417.6,200.5 414.7,193.7 417.5,200.5 412.7,204.4 	"
        />
        <polygon
          style={{ fill: fill }}
          points="341.8,399.6 346.8,406.1 347,406.1 342.1,399.8 340.2,389.2 341.6,383.3 333.4,377.9 333.3,377.9 341.4,383.2 
     339.9,389.1 	"
        />
        <polygon
          style={{ fill: fill }}
          points="311.7,374.5 321.4,374.5 318.6,376.7 327.3,378.2 330,378 327.5,378.1 318.8,376.6 321.6,374.4 329,370.4 
     333.9,363.2 340.1,359.3 342.7,359.1 345.1,360.6 347.9,360.2 350.1,358 350.8,355.5 350.1,357.8 348,360 345.2,360.5 342.7,359 
     340.1,359.2 334,362.9 329,370.2 321.6,374.3 312,374.3 296.4,370 293,369.8 292.8,370 296.2,370.2 	"
        />
        <polygon
          style={{ fill: fill }}
          points="376.6,176.6 376.7,176.7 375.2,167.3 371,156.5 371.8,147.8 371.8,147.7 370.9,156.3 375.1,167.2 	"
        />
        <polygon
          style={{ fill: fill }}
          points="398.7,218.4 402.2,222.3 398.8,218.4 399.6,216.3 398.6,207.6 399.2,206.4 402.7,204.1 402.6,204.1 399.1,206.4 
     398.5,207.6 399.5,216.3 	"
        />
        <polygon
          style={{ fill: fill }}
          points="406.3,225.5 408.3,226.7 406.3,225.5 405.3,223.1 402.2,222.3 405.3,223.1 	"
        />
        <polygon
          style={{ fill: fill }}
          points="67.6,533.7 70.7,532.9 75.4,530.6 76.3,528.8 74.7,526.3 72.2,522.4 70.3,522.8 69.1,523.6 66.6,525.2 63.3,526.4 
     63,528.9 65.2,532.1 	"
        />
        <polygon
          style={{ fill: fill }}
          points="131,587.1 130.2,585.3 126.8,582.8 124.2,582.4 123.2,585.5 123.7,588.6 127,590.1 129.8,591.6 130.7,591.9 
     132.2,591.2 131.7,589.2 	"
        />
        <polygon
          style={{ fill: fill }}
          points="131.1,566.4 129.3,567.4 127.2,569.2 125.1,569.2 124.3,566.4 121.4,567.2 121.1,568.3 122.9,570.8 125.1,573.9 
     128.7,574 132.1,573.4 132.3,575.5 132.3,578.2 134.3,579.7 132.8,583.6 133.8,587.1 137.4,585.9 139.4,579 144.4,580 143.4,576.1 
     139.6,572.1 137.8,571.9 133.9,572.1 133.1,570.7 133.4,568.4 	"
        />
        <polygon
          style={{ fill: fill }}
          points="103.8,529.1 102.4,526.7 99.8,526.9 96.5,525.6 94,527.8 93.1,529.3 94.1,531.1 96.1,532.8 99.6,534.1 
     104.8,539.5 105.7,537.1 107.8,539.1 108.8,542.6 111.1,549.6 112.1,551.2 113.7,551.3 119.3,543.9 120.8,546.5 122.7,548.8 
     125.1,548.1 125.6,547.6 124.9,544.9 124.6,543.8 121.9,541.7 122.3,540 122.3,536.8 122.7,534 125.2,530.8 129,527 128.7,524.2 
     130.5,519.4 129.6,516.1 129.1,510 126.2,508.7 123.2,509.7 120.9,514.5 119.1,513.7 118.5,511.1 113.9,512.5 108.6,514.5 
     105.4,514.9 103.7,517 103.2,521.1 103.7,523.1 104.2,525.2 106.2,528.2 104.7,529.3 	"
        />
        <polygon
          style={{ fill: fill }}
          points="99.9,513.4 99.2,510.2 98.1,509 96,508.3 92.3,507.7 91.3,508.9 91.3,516.4 89.6,519.6 89.6,521.4 90.8,523.8 
     91.7,526.4 95.7,524.3 99.1,523.7 100.4,523.2 101,521.9 101.2,519.4 101,516.1 	"
        />
        <polygon
          style={{ fill: fill }}
          points="118,385.2 117.1,388.8 107.1,391.5 105.5,393.1 104.1,396.1 103,399.1 100.5,400.8 98.4,402 96.2,403.5 
     94.4,404.8 89.4,406.6 89.8,408.6 92,410.9 96,412.6 96.9,413.6 101.4,419.9 107,427.9 110.1,429.4 113.8,429.7 119.1,429.2 
     121,424.9 125.4,420.8 127.2,421.5 130.2,422.2 130.5,419.5 128.6,407.9 131,396.1 130.9,387.9 132,379.1 131,373.3 130,371.5 
     128.4,371.1 126.7,373 125.7,372 124.1,369.3 119.4,372.8 119.5,375.2 119.5,378.7 	"
        />
        <polygon
          style={{ fill: fill }}
          points="86.7,531.5 83.7,534.1 83.4,535.3 85.9,536.9 87.5,536.2 88.3,539.8 86,540.9 86.6,544.1 84.1,544.6 82.1,548.8 
     82,551.5 85,557 85.8,558.9 87,565.9 87.9,570.3 88.9,573.8 90.1,575.1 92.1,575.8 96,578.7 96.2,580.7 102,581.7 104,580.5 
     106.5,577.5 108.7,575.4 111.6,572.9 111.3,569.7 109.8,565.8 110.7,561.3 109.8,551.3 107,545.8 102.2,540.6 98.6,538.2 95,535.6 
     92.1,533.2 88,531.5 	"
        />
        <polygon
          style={{ fill: fill }}
          points="67,487.2 68.1,486.2 69.7,484.3 70.1,480.8 70.5,478.5 65.1,467.3 66.9,466.1 67.6,464.9 67,460.2 67.5,455.2 
     68.9,452.6 70.2,446.2 69.3,440.3 67.1,438.7 64.4,438 63.5,439.5 62.4,440.9 61.1,442.6 58.9,443.1 58.7,440.5 58.1,439.4 
     56.7,437.8 54.1,436.2 52.4,433.4 54.4,430.8 51.8,428 49,427.8 49,426.4 48.6,418.9 47.7,416.3 46,415.9 45,417.5 44.6,419.4 
     44.5,422 47.6,431.8 49.2,436.9 48.3,440.7 49.7,444.3 50.6,449.9 54.1,461 51.7,463.4 49.7,462.4 49.4,459.4 46.5,459.2 
     46.5,465.3 49,470.9 50,472.9 50,475.9 52.2,479.8 57.6,480.9 62.7,488.1 64.6,487.6 	"
        />
        <path
          style={{ fill: fill }}
          d="M168.5,494.1l-2.4-3.3l-3-1.4l-1.6,1c0.1,0-7,5.1-7,5.1l1.6,7.9l1.3,2.5l1.2,3.9l3-1.4l0.5-2.2l4.1-3.7l-0.4-2.1l2.8-2.3
     V494.1z"
        />
        <polygon
          style={{ fill: fill }}
          points="70.6,610.9 71.2,614.4 71.8,617.7 72.6,619.4 74.5,619.1 77.2,616.2 78.6,614.6 79.9,611.6 79.5,607.4 76.8,603.7 
     79.4,601.1 79.7,599.8 77.4,599.9 77.1,597.8 78.9,593.7 79.5,590.1 78.5,582.6 79.9,582.2 81.6,581.7 84.5,585.3 84.9,586.5 
     89.4,589.8 89.4,591.3 89,593 86.8,595.3 85.3,597.3 87.1,598.3 90.3,596.8 92.9,594.4 94.4,593.6 95.7,590.6 94.8,590.3 
     92.4,589.2 89.4,582 89,579.6 87.6,577.5 86.9,573.7 84.2,568.2 82.6,566.4 80.4,566.1 80.3,563.6 80.7,560.9 80.2,560.5 77.9,560 
     75.4,557.6 72,553.6 69.5,553.9 70.1,559.5 69.9,562.5 68.9,564.1 68,565.5 68.1,566.7 67.4,569.6 66.5,570.7 64.1,573.8 64.1,577 
     64.6,578.1 65.5,579.8 67.1,580.1 68.3,579.3 71.3,577.6 70.9,580.3 71.4,581.5 74.2,581.4 76.5,582.1 72.7,585.2 70.3,589.2 
     70.3,591.7 70.3,593.2 70.1,595 69.5,599.5 69.9,603.6 70.9,608.4 	"
        />
        <polygon
          style={{ fill: fill }}
          points="76.5,517.2 76.2,514.2 75.5,510.7 75.2,506.4 74.6,505.9 73.1,509.2 71.6,511.4 71.4,514.3 72.6,514.9 72.3,517.4 
     73.3,518.4 74.2,519.3 73.6,521.6 75.5,524.2 76.5,524.9 80.7,524.7 78.7,527.3 80.4,528.7 82.1,527.6 84.1,523.9 85.5,519.7 
     85.5,513 84.4,509.5 80.6,505.1 78.4,501.6 75.9,498.3 74.9,498.4 74.3,500.6 75.8,503 77.1,507.6 78.4,511.1 79.4,517.1 78,518 	
     "
        />
        <polygon
          style={{ fill: fill }}
          points="48.6,355.5 47.3,352.7 44,349.3 43.3,345.4 42.2,346.1 37.3,347.5 36.8,352.2 38.4,354.9 38.3,356.9 46.1,361.7 
     48.3,364 50.9,363.9 51.2,363.6 50.6,360.6 	"
        />
        <path
          style={{ fill: fill }}
          d="M79.6,408.8c0.1,0-3.5-5.1-3.5-5.1l-7.3-8.2l-5.8-1.5l-2.4-0.8l-3-2.9l-6.8,1.6l-2.4-2l-2.1-0.1l0.6,2.6l3.6,3l3.3,2.5
     l-2.3,2.2l2.6,3l3.1,3l3.3,1.3l2-0.4l6.1,2.4l5,3.6l3.6,1.5l1.4-0.5l0.8-1.7L79.6,408.8z"
        />
        <polygon
          style={{ fill: fill }}
          points="43.7,412.8 42.6,408.9 41.5,407.2 40.4,404.8 39,402.7 38.3,401.1 37.5,397.5 33.9,399.6 33.2,401 33.4,405.1 
     33.9,406.1 36,405.1 36.5,408.4 36.3,412.2 37,412.5 38.5,410.6 41.4,414.2 43.6,417.4 44.8,414.9 	"
        />
        <path
          style={{ fill: fill }}
          d="M67.5,432.1l0.7,3.5l1.3,0.8l2.8,1.5l1.8,0.8l5.3-4c-0.1,0-1.1-3.4-1.1-3.4l-0.5-5.5l0.1-1.7l-1.4-3.9l-1.3-1.6l-5.1-4.2
     l-3.1-1.3l-4.4-2.9l-2.4,0.8l-3.7-2.3l-1.5-1.4l-3.5-2.4l-1.3,1.3l0.1,2.5l1.8,0.4l1.5,4.9l2.9,1.9l1.5,1.4l1.4-2l1.5,1l-0.2,1.6
     l0.3,2.9l-0.8,2.6l0.1,2.8l-0.3,2.3l0.7,0.4l7.9-1.1l2.3,1.2l-1.7,2.3L67.5,432.1z"
        />
        <path
          style={{ fill: fill }}
          d="M72.4,389.4l-3.6-4.4l-4.4-2.3l-3.4-3.9l-2.2-4.2l-1-3.9c0.1,0.1-1.8-2.2-1.8-2.2l-3.8-3.2l-3.5-0.1l-2.4-1.5l-6.2-3.1
     l-1.5,1.7l1.8,3.2l1.8,2.2l-1,3.5l1.4,2l3.1,3.7l2.2,2.3l3.9,3.3l1,2.9l7.1,2.4l1.1,2.8l1.9,1.3l3.8,0.7l1.5-1.2l8.3,10.3l0.8-0.5
     l-0.1-1.6L72.4,389.4z"
        />
        <polygon
          style={{ fill: fill }}
          points="35.2,396.6 35.7,393.7 36.7,391 37,389.8 33.5,383.2 33.1,380.7 31.6,378.3 29.7,376.8 28.5,380.9 29.7,391.9 
     29.7,396.1 30.8,396.4 32.3,396 34.7,397.7 	"
        />
        <polygon
          style={{ fill: fill }}
          points="52.2,258.3 47.9,256.7 46.8,257.7 42.3,255.1 41.9,254.8 40.2,255.1 39.3,257 39.6,259.7 41.4,263.4 40.2,265 
     37.7,263.9 36.6,263.6 33.2,266.7 32.1,268.5 33.1,272.2 32.9,275.7 33.3,279.6 34,282.5 33.3,285.1 36.2,289.7 37,286.1 
     40.9,282.3 41.9,281.3 42.8,281.5 43.6,284.8 43.2,287.3 43.8,291.5 45.5,290.6 45.8,287.2 48.1,284.9 53.6,281.4 54.9,278.8 
     53,266 51.6,263.9 51.6,261.1 	"
        />
        <polygon
          style={{ fill: fill }}
          points="34.9,125.5 35.5,127.9 35.5,132.1 38.5,130 40.7,127.1 42.1,124.8 40.9,122.7 39.7,120.7 36.7,118.7 35.2,117.4 
     29.6,121.9 33.5,124.2 	"
        />
        <polygon
          style={{ fill: fill }}
          points="38.4,334 39.3,334.9 44.7,336.4 44.4,332.6 40.1,322.9 38.6,322.2 34.1,323.1 33.3,324.3 33.5,326.9 35.3,326.5 
     36.9,329.3 37.8,331.6 	"
        />
        <polygon
          style={{ fill: fill }}
          points="38.6,139.4 36.6,141.7 33.7,144.1 33.5,147.2 34.2,149.6 35.1,151.4 36,152.4 37.5,153.8 41,152.7 42,152 
     41.1,147.4 40.2,144.2 39.8,142.1 40.3,140.8 	"
        />
        <polygon
          style={{ fill: fill }}
          points="21.5,293.7 20.7,288.9 19.9,285.1 19.6,281.7 17.5,285.2 16.3,286.7 15.4,287.7 13.5,289.6 12.9,292.5 12.9,296.3 
     15.7,301 15.6,303 17.8,305.3 20,306.8 22.6,305 22.9,304.5 24.1,298.4 24,296.2 	"
        />
        <path
          style={{ fill: fill }}
          d="M39.9,109.2l0.3,1.6l1.9,1.3l2.3,0.2l3.9-1.5l0.8,0.8l-2.8,3.1l-2.1,0.5l-2.8,1.2l1,3.5l1.8,2.4l1.3,0.4l1.8,0.7l0.3,2
     l5.1-1.6l2.8-1.9l3-3.3l5.7-3.6l1.5-1.2l4.7-0.4l7.1-1.1l2.5-1l-0.4-3.3l0.6-1.9l-1.5-1.7l-3.6-1.4l-11.1-1.5l-2,0.3l-1.8-1.3
     L53.6,99L47,94.7c0,0-3.3,1-3.2,1.2l-2.5,2.6l2.9,1.5l0.3,1l-3.5,0.4l-3.3,1.1l-5.3,5.2l1.3,1.5H39.9z"
        />
        <polygon
          style={{ fill: fill }}
          points="54.4,304.7 53.6,301.4 51.6,300.3 49.7,300.8 47.7,300.1 44.4,301.4 43.4,305.2 44.8,309.7 46.5,309.9 47.6,309.8 
     50.2,309.6 52,308.6 54.5,305.6 	"
        />
        <polygon
          style={{ fill: fill }}
          points="30.1,291.1 31.8,292.9 32.5,285.5 32,282.9 31.2,280.2 29,280.2 27.6,280.5 25,281 24.9,284.5 25.9,286.7 
     25.7,294 27,298.1 28.3,298.6 28.4,297.8 28.4,295.9 28.7,293.1 	"
        />
        <polygon
          style={{ fill: fill }}
          points="53.7,217.4 52.2,216.6 47.9,218.1 44.9,216.6 42.6,217.2 41.9,219 39.3,223 39.1,224.7 40.2,226.7 42.9,226.8 
     45.1,228.1 45.4,230.6 46.6,232.3 47.7,232.5 56,229.6 54.4,224.4 54.4,220.3 	"
        />
        <polygon
          style={{ fill: fill }}
          points="54.8,323.4 50.1,317.9 48.1,316.2 47,317.2 48.7,322.3 49.3,329 48.3,330.7 48.4,334.2 50.3,338 52.6,337.2 
     54.4,338.2 56.5,337.7 54.7,331.3 54.7,327.4 	"
        />
        <path
          style={{ fill: fill }}
          d="M53.8,76.6l5.2,3l1,0.6l-2.3,2.3l-3.5,2.6v2l2.5,1.2l1.5,1.5l1.5,1l1.5-0.2h3.7l3.7-6.8l-1-5.7l1.7-4.9l-1.4-2.5l-0.8-2.5
     l-1.4-2.4l-1.3,1.5l-3.5,2.3l-5-2l-2.4-1.1c0,0-2.2-1.4-1.9-1.7l-0.8,0.4v4.9l1.1,5.3L53.8,76.6z"
        />
        <polygon
          style={{ fill: fill }}
          points="62.2,277.4 63.1,275 62.2,272.2 58.3,266.4 55.1,264.3 55.7,275.9 59.2,277.4 	"
        />
        <path
          style={{ fill: fill }}
          d="M406.9-6.3h-0.1H268.6h-5.7h-52.5h-6.8h-21.2l-1.7,1l-6.8,4.8l-1.6,2.3l-7.4,5.6l-2.8,1.7l-1-0.2l-1.3,1.4l-2.1,4.6
     l3.7,1.4l1.5,2.4l0.7,4.3l11.3,2.9l0.8,1.4l-0.5,1.2l-3.6,0.2l-5.8-2.2l-2.3-0.2l-2.1-3l-0.8-3.8l-1.2-0.6l-4.9,2.3l-0.1,1.1
     l2.2,3.9l1.7,1.9l1.7,0.5l3,5.2l3.5,2.8l3.4,4.1l0.4,1.9l2.1,2.9l1.8,0.6l2,2.2l1.5,3.5l4-1.3l3.1,0.7l4.8,1.4l3.6,1l3.3,1.5
     l0.7,1.7l-0.6,1.9l-5.7-1.3l-2.1-1l-1.9-0.8l-4.4-2.1l-4.7,1.9l-2.2-0.6l-1.7,0.4l-1.9,1.3l-1.2,3.8l-0.8-1.2l-0.2-3.4l1.9-3.2
     l-0.1-1.8l-2.2-2.3l-3.2-1.6l-3.6-6.7l-2.8-0.6l-2.1,1.6l1.3,4.6l-1.1,4.3l-2.9,4.3l-2.6,6.8l-3.4,3.9l-3.2,7.1l-0.8,0.1l-0.4-1.7
     l0.8-2.6l5.6-11l1.7-4.2l2.5-3.9l0.4-2.4l-0.6-5.1l1.4-4.9l-1.1-3.5l-2.9-4.4l-1.6-1.8l-1.6-0.6l-3.2-0.5l-1.9,1.4h-4l-0.7,2.9
     l-1.8,1.1l-4.7,1.6l-1.2,0.6l-5.6-0.6l2.5,9.3l-0.5,2.8l6,6l-1.8,2.6l-5.9-7.3l-2.6-12.5l-3.2,1l-2.4,0.5l-4.4-0.7l-4.4-5l-3.3-2.4
     l-5,4.8l-0.8,5.1l-1.1,0.8l-2.8,6.1l2.9,2.8l0.7,3.3l2.8,0.8l2.5-0.7l8.6,0.7l1.1,1.6l4.9,3.3l0.8,2.5l-0.2,2.9l-0.7,1.2l-1.1-1.3
     l-3.7-3.4l-2.4-2.5l-3,0.7l0.7,7.3l-1.6,2l-0.6,2.2l-1.6,1.2l-0.6,2.8l-1-0.3l-0.1-2.5l1.6-1.6l-0.2-2.2l-1.4-3.7l-3.6-3.2h-1.4
     l-4.3-4.6L92,50.7l-1.4-2.4l-3.2-2.4l-0.8-3.4l0.5-2.4l-1.8-1.8l3.1-1.3l0.3-2.3l-5.2-1.9l-3.1-0.8l-1.7-1.9l-1.2-2.1l-0.4-3.4
     l-4.4-1.2l-0.5,0.3l-0.3,3.3l-2.2,2.5L68.3,27l-2.1-0.5L63.6,28l-0.8,1.5l-0.5,4.7l1.1,2.8l1,1.2l3.4,1.3l2.8,4.9l-0.7,1.2
     l-3.7-1.3l-1,0.5l-0.1,4.8l0.8,1.1l11.8-0.7l1.5,0.5l3.5,4.4l3.9,1.4l3.5,3.6l0.1,3.9l-0.3,1.8l6.5,0.8l1.9,0.8l1.7,2.4l-0.8,1.2
     l-1.7-0.5l-2.6-1.8l-1.8-0.6l-2.2,0.2l-1.7,5.3l-1.3,3L85,76.7L82.5,79l2.5,3.3l0.4,1.7l-1.1,1.6l-1.9-2l-2.4-5L79,77.9l-4.3,1.4
     l2.3,7.2l0.3,3.1l-3.5-5.2l0.2-7.3l-2.9,0.1l-1.4,2.2l0.1,4.3l-2.2,5.1L66,92.4l1.7,0.6l1.3,1.3l2.2,2.5l1.5,1.1l1.6-0.7l3-3.6
     l2,1.1l5.9-0.4l2.8,0.7l3.6-1.2l3.4-3.6l11,0.8l3,0.1l9,0.4l3.9,2.1l5.2,1h4.1l5.2-1.6l10.5,1.2l4.8-1.5l7.9,1.5l1.1,0.8v1.1
     l-1.3,1.1l-8.5-0.6l-5.4,1.5l-5.4-1l-6.8,1.2l-0.2,0.7l4.3,4.8l4.7,3.3l3.7-3.5l3.5-0.5l7.4-0.1l9.9-0.6l3.4,1.1l4.8,3.5l2.3,2.1
     l9.2-1.9l6.2-0.8l3.4-0.8l3.9,1.2l6.6,5.5l4.7,1.3l8.9-1l1.1-6l3.3-4.9l7.4-1.9l5.1,1l3.8-3.9l-0.7,2.8l0.4,1.9l1,0.6l4.4,0.3
     l3.7,2.2l4.6-1.5l3,1.6h-4.4l-1.9,7.6l-1.5-4l-4.1-2.8l-8.3-2l-1.5,0.5l-2.2-0.7l-6.5,1.3l-0.5,1l3.4,2.4l-0.6,1.8l-4.8,3.6
     l-3.8,4.8l-3.1,0.8l-3.4,0.1l-8.2-2l-4-2.8l-3-3.3l-2-0.2l-2.3-1.5h-4.3l-7.2,3.2l-2.5-0.4L176,113l-5.3-3.9l-0.1,2.5l3.7,3.3
     l0.4,1.5l6,6.9l1.1,1l3.6,1.1l-0.1,1.2l-3.8,2.1l-6.5-3.7l-7.1-11l-2.3-5l-2.3,0.5l-1.9,1.6l-1.1,8.4l-2.8,10.6l-3-0.2l1.5-4.3
     l2.5-10.4l0.7-6.5l-4.1-0.6l-3.4-1.6l-5.5,0.7l-5.5,3.1l-6.9-2.1l-1-1.8l-2-5.4l-1.3-0.6l-6.4-1l-5.8-2.5l-5.8,2.4l-1-1.1l0.5-2.3
     l-3.3-0.8l-4.6,0.1l-2.8-2.2l-1.5-0.3L93.8,95l-3.2,3.5l-2,3l-3.4,3.4v1.8l2.6,2.3l-3.4,2.4l-4,3.8l-5.1,2.6l-3.1-0.3l-2.2,0.8
     l-3.5,2.8l-3.7,4.4l0.8,3.9l4.2-1.4l2.2-1.6l5.4,0.4l5.1-1.7l4.2,1.5l-2.4,1.7l-3,0.4l-4.8,1l-5.7,0.8l3.9,4.2l3.8,2.1l-0.6,1.1
     l-4.6,0.4l-2.4-1.1l-0.5-2l-2.9-2.1l-3.6-2.2l-4.8-1.3l-5.4,4.2l-3,4.3l0.3,7.7v2.5L50,151l2.4,4.8l-1.1,4l3.8-0.6l8.6-1.9l1.8,1.3
     l3.9,1.2l8.8-1.2l3.8,0.5v1l0.3,2.2l-8.2,0.4l-7.4,1.5l-3.4-0.4l-3-0.1l-1.3,1.4l-5.9,1.3l-1.4,0.8v4.6l4.7-1.5l1.2-1l10.1-2.6
     l8.3-2.3l8.5-0.2l4.4-0.3l5.9,1.3l4.6,2.2l3.3,1.9l-0.1,3.9l-0.4,0.4l-10.5-3l-17.7-4l-2,1.5l1.9,2.2l4,0.2l4.6,3.1l2.2,0.3
     l4.9,2.8l-2.1,2.4l-2.9,1.2h-4.4l-2.6-1l-2.3-2.1l-3.6-0.2l-0.2,1.9l-1,5.7l1,2l4.8,1.8l3,1.7l5.4,0.2l13.6,1.6l4.8,0.5l0.8,1
     l1.1,2.3l0.4,4.3h5.2l1.9-1.9l9.4-4.2l0.6-0.8l5.4-4l2.4-0.8l4.1,1.2l4.4,1.8l1.6,1.4l3.2,6l-1.1,1l-1.5-0.7l-1.8-1.4l-3.3-4
     l-1.6-1.9l-3.6,0.4l-1.8,0.3l-1.1,1.3l-3.6,3.4l-2.9,3.5l-3.9,0.1l-1.4,0.6l-2.3,1.3l-3.8,1l-7.4-1.6l-2.8-2.4l-1-3.6l-1.2-0.7
     l-9.2-0.2l-7-0.6l-3-0.4l-1,1.7l0.8,4.4l-6.5-0.5l-8.9,1.1l-2.3,0.8l-3.1,2.4l-5,1.9l-2.6,2.2l1.5,2.9l2,0.8l8.6-4.4h3.8l-1.8,3
     l-1.4,2.4l-3.9-0.2l-2.4,2.1l-1,3.8l3.1,7l2,1.7v3l0.8,1h1.8l2.2-1.7l6.1-1.3l0.6-3.9l1.4-1.6l3.1-0.5l10.9-2.4l7.6-0.1l1.6-2.4
     l1.4-0.4l3,0.2l3.6,0.6l5.9,0.5l-1.8,1.9l-3.7-0.8l-2.4,0.4l-5.6,1.3l-3.8,2.5l-1.2,1h-4.1l-4.6,0.6l-5.9,1l-3.1,0.5v2.3l0.4,3.9
     l2.1,1l0.4,1.1l-2.2,0.6l-3.1-0.6l-2.6-2.6H68l-1.1,1.6v2.3l-2.4,0.5l-1.5-0.3h-3.4l-3.4,0.8l-1.7,2.3l-4,0.5l-0.6,3.7l0.6,2.2
     l1.2,1.2l1.6,1.2l0.6,1.1l-0.4,3.7l0.2,1.3l5.9,0.4l13.1-1.8l3.7,2.1l4.3,3l5.9,4.1v0.7l-2.4,2.8l-3.1,0.3l-2.3-1.1l-2.8-3.4
     l-6.1-3.1l-4.6-0.4l-3.1,1.3l-1.4,7l4,1l6.5,3.8l1.2-0.1v-1.8l1.2-1.3l2.1-0.2l0.8,1.5l3.3,1l3.9,3.4l-0.5,1.2l-1.9,0.5l-2.9-3.4
     l-3.7,0.1l-0.8,2.2l3,1.8l0.3,4.7l2.4,0.5l3.8-1.1l2.9-1.5l1.9-1.7l2.3,5.1l3.4,2.9l1.1,3.8h3.1l3.3-1.9l6.1-5.4l2.4,0.4l4.3-2.8
     l5.1-6.7l3.1,0.6l2.3-1.8l2,0.3l3.5,2l2.3-2.1l-0.4-3.6l0.8-1.1l7.9,1.3h3.3l3.9,0.1l1.1,0.2l6.4-1l5.3-1.9l1.3-1.9l5.8-3.2
     l1.4-5.3l1.7,2.5l-1,4.6l-1.6,1.5l-3.9,1.4l-1.6,2.3l1,1.3l3.9,2.5l1.3,0.4l3.3-0.8l5.3-1l2.3-1.4l2.3-0.1l2-4.2l2.4-2l0.2,1
     l-2.3,4.2l3.5,2.2l3.9,5.1l3.7-0.2l1,1.4l2.4,0.4l0.6,1.3h1.2l1,1.8l1.3,0.4l4.4-1.2l1.5,0.8l5.3-0.6l-0.5-2.3l0.7-1.1l3.3-0.3
     l4-1.8v-6.1l-1.6-5.1l1.1-4.9l-5-1.9l-1.3-2.3l2.4,2l4.2,1.4l1.8-0.5l0.6,1.2l0.5-0.5l-2.3-3.4l0.8-3.4l-1.8-4.3l-4.2-3.3l0.6-0.3
     l2.1,1.5l2-0.3l1-6.6l1,1.4l0.1,5.9l1.1,3.9l3.8-2.8l2.1-3l3.2-1.9l0.1-3.1l3.7-8.9l3.4-3.5l2-7.7l-0.2-3.2l1.4-3.5l0.6,0.4
     l-0.1,3.4l-1.8,12l-2.8,2.8l-2.5,5.5l-1.2,3.2l0.4,2.4l-0.8,1.8l-3.1,2.5l-3.1,4l-3.6,2.3l-0.5,3.1l1.2,1.5l0.4,2.8l2.5,0.2
     l1.1-0.7l5.7,1.5l1.9,1.3l1.9,3.6h6.6l6.5-1.1l1.3,0.3l0.5,1.5l1.8,1.1l2.1-0.2l2.6,3l1.6,0.5l2.9-0.7l4.7,1l-0.1-2.5l1.8-2.4
     l1,0.2l0.5-3l3.3-1.5l0.7-2l2-0.2l1.7-1.3l1.2-2l-0.3-1.4l1.9-0.7l-0.7-3.3l3.5-3.1h1.8l0.5-1.5l0.7,2.8l-1.1,1l-3,0.2l-0.2,2.4
     l-1.5,2l-0.2,3.8l0.3,0.7l3.3,0.6l2.4,1.7l-10.4,0.5l-1.1,1.5h-2l-1.6,2l-0.4,2.1l-3.7,4.8l-1.2,0.5l-2.8-1l-3.6,2.3l-0.2,1.1
     l1.7,2.4l0.1,3.5l1.8-0.2l3.6,1.8l0.8-3.6l4.1-2.8l2.8-1l1.4,0.6l2.8-0.2l5.3-1.8l2.8,0.3l0.3-4.3l1.9-1.5l5.4-0.5l-0.6-2.8l2.4,1
     l-0.7,1.4l0.6,1.1l7.4,2.5l7.3-5.7l-4.6-3.4l-1.7-12.8l-1-2.9l-5.3-3l-1.9-3.5l-1.8-1.4l1.9-1l5-5l1.7-0.6l2.1-3.3l-0.4-5.8l-5-3.8
     l-1.4-3.1l1.5-0.3l1.9,2h2.2l3.2,2.4l1.5-3.5l2.8-2l3.8-1.4l1.6-6.5l8.2-4.4l4.6-4.8l0.5-2.1l1.7-1.5l0.8,1.7l-1.6,3.5l-3.3,4
     l-3.3,1.1l-2.6,2.4l-1.4,6.5l-2.6,2.3l-2.9,1.1l-2.2,3.2l-2.6,5.5l0.5,4.6l-1,2.3l-3,3.3l-4.9,2.3l-0.3,1l2.1,2.5l2.6,1.3l1,2.2
     l1.7,1l1.6,13.3l2.9,1.5l6.5-0.2l4.1-1.9l2.5,0.6l9.4-1.5l1.1-3.8l1.8-2.4l3.3,1.8l2.9-0.6l0.7,0.7l-0.4,1.4l-2.1,1.2l-3.5-0.3
     l-1.4,3.6l-2.3,2.5l-12.1,0.8l-4.7,3.2l-1.6,0.3l-3.6,4.6l-5.4,2.2l4.4,2.8l1.3,5h2.4l2,0.8l0.7,1.1l-0.7,1l-1.7-1.3l-2.4,0.8
     l-1.4-0.3l-2.6-1.7l-1-2.3l-2.8-1l-3.5,1l-2.3-1.2l-3.9-3.8l-2.8-1.1l-1.5,4.3l-1.9,2.3l-2.1,0.6l-3.8-0.5l-2.5,1.7l-4.4,1
     l-2.4-0.1l-0.8-1.1l-2.8,2l-1.6,3.5l1.5,1.5l-0.1,2.4l1.1,2l-1.5,3.4l0.5,3l2,2.6l-0.3,1.5l1.7,2.5l-0.2,2.6l1.4,3.5l2.4,1.5
     l1.8,2.9l6.2,1.5l1.3,2.9l0.2,3.6l-1.2,3.5l-1.4,0.4l-3.1,6.1l-0.3,2l-2,1.4l-1-0.5l2.6-4.8l2.2-6.8l-0.7-2.3l0.4-3l-6.5-3.9
     l-5.2-6.8l-2.3,3.1l-4,2.4l-2.1,0.2l-2.6,2.3l-0.5,1.4l-4.6,1.6l-2.4,2.1l-0.5,0.6l0.5,1l-0.4,4.3l-4.6,6.1l3.7-7.4l0.8-6.1l6.5-3
     l0.3-2.6l3.4-2.5l2.1-0.2l3.6-2.2l1.2-3.5l1.1-0.8l-0.5-4.4l-2.4-6l0.6-2.6l-0.8-2.4l-1.9-1.2l-1.2-2l-2.4-1.3l-1.1,0.4l-0.7,2.5
     l-1-1.1l-0.8,1l-1.4-1.9l-3.5-1.3l-0.7-2.5l0.7-1.8l-0.5-4.8l-2.9-3.6l-3.3-0.5l-1.1,0.8l-3.4,0.1l-1.8-1.6l-6.9-0.1l-3.2-2
     l-2.4-3.3l-1.4-0.2l-1.5,6.7l-3.3,8.5l-0.6,4.4l-1,0.1l-0.8-2.4l-3.5-0.5l-3.9,4.6l-4.3,0.3l-1.6,0.8l-1.6,1.8l-1.5,7.5l-2.2,3.1
     l1.8,3.5l-5.1-0.2l-1,1.5l-1.1-0.7v-1.1l3.9-1.8v-2.8l2.8-5.7l-0.1-3.1l-7.2-0.2l-3.1,3.6l-1,2.8l-2.5,1.8h-1.9l0.1-1l2.4-0.2
     l1.5-1.1l0.3-3.7l1.8-2.9l-0.5-2l-1.7-1l-0.1-1.5l-1.4-1l-4.9-1.1l-3.6,0.4l-2.3-3.2l-3.2-0.8l-1.5,0.2h-2h-1.5l-5.4,1l-1.9-0.1
     l-2.5-0.4l-3.5-2.2l-1.6-0.6l-10.1-0.1l-2.4,3l-1.4,1.5l-0.8,2.5l-1.1,4.1l-3.4,7l-0.8-0.5l-0.2-2l2.4-5.3l0.3-8.2l-1.9-1.2H140
     l-2.3,2l-4.6,4l-3.8,1.6l-3.1-3.1l-2,0.5l-3.7,3.9l-5.2,4.3l-6.1,0.4l-2.1,1.8l-1.7,1.7l1.2,6l-3.1-0.3l-2.3-0.4l-3.7,0.3l-4.6-2.2
     l-4.2-2.1l-2.6-3.9l-1.4-1.4l-6-0.2l-3.1-2.8l-2-0.4l-4-1.3l-0.6,1l-1,2.2l-5-0.3l-4.4,1.8l-1.6,1.3l-1.9,5.7l0.6,2l0.5,4l0.1,1.7
     l0.5,4.6l3.3-0.2l2,1.5l3.6,1.8l1.2,0.5l0.8,5.7l-1.3,2l-0.8,0.5l-0.1-2l-0.6-4.7l-1-0.3l-1.8-0.2l-4.7,0.3l-1.6-1.6l-3.2,1.1
     l-0.1,1.5l0.7,4l1.2,1.6l1.6-0.4l0.1,1.8l-0.2,3l-0.4,2.8l1.4,2.1l1.4,3.9l0.6,1.7l1.5,2.8l-0.5,1.4l2.9,2l2.8,1.2l0.5-5.5l3.5-6.7
     l1.1-3.6l3.7-2.5l2.2-2.6l5.9-1.6l1.2-1.1l-0.3-1.5l0.1,0l0.3,1.5l-1.2,1.1l-5.9,1.6l-2.2,2.6l-3.7,2.5l-1.1,3.6l-3.5,6.7l-0.5,5.5
     l0.2,0.4l2-2.4l1.6-0.2l1.6-0.2l2.8,1l1.5,1l2,1.5l1.3,0.6l-0.2-1.7l2-0.3l2.2,2.8l0.3,1.7l2,0.6l1.5-1.3l0.5-3l2-0.3l1.5-8.9
     l4-2.1l2.9,0.8l6.6-2.6l1.6,0.1l0.7,1.7l2.5,0.8l5.6-0.2l2.5,0.5l-4,1.8l-4.4,2.9l-1.6-2.1l-0.6-2.1l-1.9-1l-6.1,2.6l-3.7-0.5
     l-1.9,0.7l-1.9,6.2l3.3,3.1l0.2,1.1l-2-0.4l-3.7,1l-0.2,2.9l-1.2,1l-2-0.4l-3.5,7l0.2,2l5.2,2.6l3.3,0.7l2.1,1.4l1.2,1.9l1.8,2
     l2.1,3.1l0.7,1.3l2.6,2.6l3,3.6l-0.1,3.5l-7.8-9l-0.8-0.2l-0.7,3.2l-1,2.6l-2.2,0.8l-2.6-6.1l1.9-2.2l-4.3-5.8l-3.8-1.6l-1.8-1.5
     l-7.3-1.6l-4.3-3.9l-2-1.5l-3.1-0.6l-2.6,0.3l-2.4-5L56,345l-1.1-0.7l-4.6,0.3l-2.4,1.5l-2,0.3l0.5,1.5l3.6,1.7l3.5,2l2.4,1.2
     l1.7,3.8l1.2,2.9l3,3.1l2,2.2l-0.5,2.3l3.5,3.5l3.9,2.1l7.3,7l-1,4.3l-2.1,0.7l-1.7-4.1l-3.8-3.2l-4-1.7l-1,0.7l-0.1,1l-0.2,3
     l10.2,8.2l2.8,8.2l4.6,7.3l0.7,2.9l1.9-0.5l1.7-2.6l2.4-1.7l3.4-0.1l2.4-1.7l2.1-3.5l2.2-2.3l1.7-0.5l2.9-2.8l0.6-2l3-2.1l2-0.5
     l1.5,1.4h1.3l2-1.6l1.5-2.2l0.1-3.7l-3.3-9.2l2.4,0.8l3.8-1.4l1.6-2.8l3-2.9l1-0.7l1.8-1.9l1.3,0.5l-1.6,3.4l-0.3,1.5l2.9,2.5
     l2.6,0.4l2.1,2.4l0.6,5.1l-0.6,15.1l-1.4,7.5l-0.5,6.2l1.5,13.6l1,1.7l-0.8,3.5l-3.5,0.7l-3.9-2l-1.6,0.3l-1.5,1l-2.2,5.9l-3.1,0.2
     l-3.2,0.8l-3.7,0.6l-2.2-0.8l-3-3.5l-3.9-3.1L93,415.2l-6.6-4.2l-2.4-0.6l-2.2,2.6l-1.8,2.1l-0.8,5.9l1.5,3.7l0.4,1.1l0.5,2.3
     l0.6,3.5l2.4,3.4l1.8,0.7l0.1,1l-1.5,1l-0.7,0.4l-3,1l-2.3,0.7l-1.2-1.6l-2.3,1.2l-1.8,1.2l-2.2,2.1l-0.5,4.3l-0.6,3.2l2.4,2
     l0.6,1.9l0.1,3.7l-0.4,4.6l1.5,3.9l1,1.2l0.8,3.4l2,2.2l4-3.5L87,467l0.6,2.4l-3.8,4.2l-1.1,2.3h-1.5l-1.4-0.3l-1.7,1.4l0.1,3.5
     h2.5l4.6-2.2l2.3-2.3l2.6-0.8l2.5,2.6l-0.1,1.6l-1.7,2.3l-1.5-2l-1.8,1l-0.6,3l0.7,1l1.8,0.2l2.9,6.1l-0.8,2.8l0.4,0.7l5.5-3.9
     l0.2,3.4l0.1,0.5l1.5,2.6l1.5-2.4l0.4-2.8l-0.6-2.8l1.3-2.5l1.5-0.5l2.9-1.5l2-0.7l2-2.2l0.7-1.8l3.3-7.8l-1.4-2.3l0.2-1.1l5.3-5
     l1.5-8.5l0.3-2.4l2-8.7l2.1,0.4l1.8,0.3l3.3-1.6l0.8-1.3l-0.7-2.4l0.5-2l0.8-0.3l3.1,1.1l0.8,1l0.4,2.3l-2,3.1l-7,5.6l-3,2
     l-0.4,6.7l-1.7,5.4v2.1l-4.8,2.4l0.8,2.4l-0.7,3.2l3,0.5l4.4-2.6l7.9-0.3l1.5,1.4l-6,2.9l-3.1-0.6l-4.9,5.7l-3.4-0.4l0.4,5.2
     l-1,2.4l-1.2,3l0.2,1.4l1.5,2l5.8-1.2l4.6-3.2l1-1.9h3l1,4.4l2.5,2.4l1.5,2.9l-3.1,0.8l-1.1,1.2l-0.7,2.8l1.4,2.2l5.5,4.7l1.5-3.9
     l4.4,0.5l-4.9,8.6l-3-3.1l-1.6-0.5l-2.5-1.9l-1,0.7l0.3,10l0.6,5.5l2,0.2l3.5,4.2l4,0.2l2.1-0.3l2-1.5l0.8-2.1l4.1-3.5l1.9-3.6l1-3
     l0.3-3.6l-0.3-4.8l-0.6-5.4l-2-5.9l0.5-5l0.8-1.4l1.5-0.5l0.2,3.5l2.8-1.9l0.3-4.3l1.6-4.6l-0.5-3l4.6-4.2l-0.4-2.1l1.7-3.8
     l-0.4-2.5l1.8-1l1.7,2.1l5,2.6l4.2-0.3l1.2-1.4l-0.4,2l1.4,1.6l1.6-3.5l1.5-0.7l1.1-2.1l1-2.5l-1.4-1.2l-0.8,0.4v-3.7l-1-1.8
     l1.2-2.1l4.3-1.8l0.5-1.8l-1.4-2.2l-3.7-0.7l-3.3-4.9l2.4,2.3l3.9-1.5l0.2-2.6l-0.8-0.3l0.5-1l3.2,1l2.1,2.3l3-2.6l-2-4.3l2.6,2.4
     l1.4,0.1l5-1.9l1.2-2.2l1.6-1h3.2l1.1-3.6l1.5-0.5l1.7,0.6l3.1-1.2l7-4.9l1.5-0.3l2.4,0.8l4.4-1l0.5-2.9l3.6-0.8l1-2.4l4.2-4.1
     l1.4-3.2l1.6-0.7l-2.5,5.4l-1.6,1l-5.1,5.9l0.2,3.8l2.4,0.4l2.5,3.9l2.4-0.3l0.6-2l5.5-3.7l-0.2-2l1-1.2l3.2-0.4l4-3l3.7-0.4l1.9,1
     l1.5-1l3-7.4l-1-1.7l0.4-5.6l-0.5-2l2.1-1.7l1.7-0.2l-0.5,1.4l-2,1.4l0.6,2.3l-0.7,3.4l0.6,1.5l8.5-11.2l2.2-1.7l0.8,0.4l-8.3,11.4
     l-0.5,3l-3.9,5.8l2.1,1l2.4-2l4.8-1.3l8.5,3.9l5.9-2.8l1.8,0.2l-0.8,1l-3.6,1.5l-0.6,1.3l-1.4,0.4l-1.2,2.6l-1.5,0.5l-3.2-2.9
     l-3.9-1.1l-1.7,0.1l-4.1,2.2l-5.5,1l-3.5-1.4l-2.8,0.1l-3.1,4.8l-3.5,3.3l-1.8,0.7l-2.2,5.5l-3,2.5l1.9,4.1l-1.4,0.8l-3-2.5
     l-3.2,8.2l-0.8,8.3l-3.8,7.1l0.7,4.7l-1,1.9l0.6,2l-1.8,3.5l-1.8,9.6l-2,3.2l0.8,1.5l-1,3l0.4,1.3l-1.7,4.1l0.1,2.9l-1.1,3l0.6,4.6
     l-0.8,1.3l-1.6-1.9l-0.4-3l1.5,1.3l-0.8-2.5l1.1-3.1l0.3-7.4l1.2-2l-0.6-3.7l1-5.6l1.3-1.1l-0.5-1.2l1-2.9l-1-5.2l1.5-5.8l1.8-1.6
     l2-6.2l-0.6-5.3l2-2v-4.1l2.2-3.5l-0.1-3.5l2.8-3.7l-0.1-0.7l-5.9-4.4l-4-1.8h-2.1l-5.9,7.1l-1.3,4.7l-8.8,3.3l-3.4,0.2l-2.8-0.8
     l-1,1.5l0.8,1.8l-0.3,1.5l2.5,1.8l-3.5,1.3l-2.8,4.3l-3.5,2.3l-1.7-0.5l-5.6,3.3l2.1,0.7l1.2,3.5l-2-0.5l-0.1,3.6l-3.1,0.5
     l-0.5,1.3l0.5,1.5l-1.6,1.9l0.5,2.3l-2,2.2l-1.6,0.5l-1.5-1.4l-3.4,0.2l-1.5,4l-2.2,2.6l1.4,1l-0.2,1.8l1.5,1.3v1.6l2.6,4.8l1.8,1
     l2.1,2.5l-1.5,2.1l-1.5,0.5l-0.3,1.4l7.3-1.6l4.8-3.5l1.8-2l1.6-5.5l2.2,0.1l-1.7,3.2v3.1l3.7,1v0.8l-8.6,2.4l-3.5,2.9l-1.3-0.4
     l-5.9,2.5l-2.9-0.4l-1.5,1.8l-7.2,4.6l-2.2,2.8l-1.9,6.5l-2.1,5.6l0.4,2.4l2.8,1.1l-1.8,4.4l-3.9,3.8l-4.6-1.9l-4.8,3.5l-1.9,2
     l-5.1-0.7l-2,1.6l-2,3.2l-1,2.4l1,3.9l-2.5,1l-4.4,3.7l-2,1.6l-1.6,0.8l-0.1,2l1.4,1.4l0.6,0.5l1.8,1l2.2-0.2l3.3-0.5l1.8,0.1
     l2.2,1.9l0.1,0.5l2.2,5.7l5.8,5.3l1.1,0.2l3.6-4.9l2.2-0.5l2.6-3.9l2.5,0.5l-2.8,3.7l0.1,1.8l2.5,4.2l1.4,1l3.6,1.6l3-0.2l2.1-1.9
     l2.9-5.3l1.4-0.4l5.5-1.8l0.7-2l3.1-1.9l3.1-3.4l13-5l3.3-4.8l1.1-0.1l-2.2,2.2l-0.6,2.6l-1.6,1.3l-7.1,3.5l-2.8,0.2l-3.9,5.7
     l-3.6,3.2l-4.1,1.5l-2.5,7l-2.4,3l-5.7-1.5l-5.5-0.2l-4.1-0.1l-5.7,1.1l-2,1.3l-3.5,1.9l-3.3,4l-4.9,5.5l1.1,8.7l-2.1-1l-9.9-4.2
     l-2-0.3h-2.6l-3-2l-0.3-1.5l-2.2-0.2l-0.3,1.4l-3,3.2l-1.3,3.9v1.6v2.4l0.1,5.5l0.5,1.6l0.5,2.5l0.3,5.4l-0.8,1.4l-1.1,1.1
     l-0.6,1.6l0.2,0.2l-0.4,1l-3.4,4.9l-1.6,0.2l-3.2,0.2l-0.5,5.9l-1.1-1.4l-0.1-3l-6.2,1.5l-0.8,0.8l1.2,1.3l-1.1,0.7l-3-0.1l0-0.2
     l2.7,0.1l1-0.7l-1.1-1.3l0.8-0.8l6.2-1.5l-0.4-2.2l1.5-2.2l1.7-0.2l1.6,1l0.8-0.3l1.3-1.3l-1.2-5.6l1.7-1l1.6-1l1-1.9l-1.8-4.4
     v-5.5l1.1-7l0.2-1.5l0.8-5.7l0.3-6.2l-0.3-4.2l-1-0.5l-3.1,3.8l-2.4,1.3l-1.4,1.3l-3.1,3.6l-0.6,1.9l-2.2,3.4l1.9,2.4l2.2,3.4
     l2.9,2.6l-3,4.4l-0.5-1l-2.8-4l-1.4-5.5l-1.9,0.3l-2.2,1.7l-1.5,2.3l-4.4,2.9l0.3,2.9l-0.5,1.8l-2.8,1.9l-1.7,5.3l-2.1,2.2
     l-1.7,2.1l-1.4,3.6l0.2,3.1l1.7,2l0.1,0.1l-1.9,0.4l-0.5,1.1l0.3,2.4v1.5l0.6,2.2l0.5,2.5l0.3,3.7l0.2,1.4l1.8,1.7l0.4,3.4l1.6,0.4
     l2.5,4.7l2.3,6.8l1.2,4.7l0.6,3.4l0.2,4.8l1.9,5.7l1.7-1.6l1.6-2.1l-0.5-2.8l0.4-4.1l-0.2-2.6l-0.2-0.8l-0.3-2.1l-1.1-6.4l1.8-0.4
     l1.1,7.1l0.5,4l0.2,1.2v3.4l2.3,1.8l4.1-0.4l1,2.4l1.2,2.1l1.6,0.6l3.3,0.3l2.9-0.2l1.1-0.4l3.6-6.5l1.3-3.7l1.5-2l0.2-5.1
     l-0.8-3.4v-2l1.2-0.7l0.5-2l-1.5-3.2l-3.1-1l1-10l0.7-2.2l-0.6-1.8h-0.7l-2.6,1.7l-0.3,3.8l-2.3,2.8l-1.4,1.1l-3.3-0.2l-3,0.2
     l-0.5-0.2l0.6-6l4.4,1.9l3.5-4.8l3-4.2l2.4-1.9l4.2-0.2l0.4,0.4l0.3,2.9l-2.3,2.8l1.3,13.6l1.2,2.2l1.9,8.4l0.7,4.8l-1.8,4.2
     l-0.4,4.1v4.3l-0.7,2.4l1.5,2.5l1.9-2l-0.3-4.2l3.2-2.4l3.6-2.5l2.6-3.8l-0.2-3.2l3,1.5l1.5,1.1l0.6,1.6l3.7-1.3l5.4-2.6l1.8-1.7
     l0.7-6.8l-3.3-2.6l-1-1.8l-1.8-7.2l-7.8,2.8l-1.7,1.3l-0.3-3.1l-1.8-4.6v-1.8l2-0.2l1.6,5.8l4.2-3.1l3.2-1.1l2-2.1l-0.3-4.4
     l-3.9-7.5l1.2-1.1l2.4-3.5l1-0.4l1.3,0.7l-0.3,7.3l3.3,2.1l0.1,2.3l1.3,2.6l8.6-0.3l2.3-1l3.4,0.2l3.5,0.8l2.6-0.2l7-1.5l1.1-1.5
     l2.4,2.9l-3.5,0.3l-9.5,2.9l-6.2,3.3l-4.3,0.6l-3-0.5l-2.1-1.8l-1.8,1.1l-0.2,4.4l1.4,2.9l0.7,4.9l1.4,6.5l1.5,1.6l1.9-0.1l1.1-3
     l1.9-2.2l2.2-2.3l4.4-2.5l2-0.7l5-3.2l4.2-2.1l1.5-2.4l4.7-7.3l-0.4-2.6l-0.7-2.9l5-4.1l1.9-3.3l-0.3-2.6l0.8-2.3l3.5-6.8l-0.7-3.2
     l-0.7-2.9l1.5-3.5l1.4-1.3l3.1-1.7l2.4,0.7v1.2l-1.6,1.7l-2.3,1.3v8.9l-1.6,3.6l-2.1,1.8l-3.1,9.2l1.4-0.1l4.9-3.3l3.3-0.8l5.3-2.1
     l4.1-2.3l16.8-0.8l-8.4,2.3l-9.9,2.8l-4.8,2.6l-12.9,4.7l-2.1,2.2l-0.4,2.8l1.1,2.1l-5.4,13l-7.5,0.8l-2.5,1.5l-3.2,1.7l-3.7,1.5
     l-1.5,3.3l-1.4,3.5l-0.6,2.3l2.8,3.2l4.8-0.1l3.4,1.3l2.2,1.8l3,0.1l2.6-1l4.2-1.1l1.3-1.6l3.2,2l2.3,0.8L194,676l-1.7,0.7
     l-10.6,0.5l-4-0.5l-1.4,0.4l-1,1.1l0.6,3.4l4.1,5.5l3.7-2.3l9.3-3.9l6.4-3.1l1.7-2.5l2.4-0.8l5.7-1l4.8,0.2l-5,2.1l-12.4,7.2
     l-4.1,2.2l-8.7,2.5l-2,2.3l-2.3,2.4l-5,3.3l-1.1,0.5l-1.5,2.2l-1.8,3.1l-1.9,3.5l-3.1,7l2.5,1.3l5.8-3.3l6.6,0.6l1.1,1.2l-0.3,1.6
     l-8.8,0.6l-3.5,1.5l-3.6,2.4l-4.7-1.7l-3.3,1.5l-1.7,1.4l-5.7,5.4l0.8,3.9l1.6,2.3h96.5h0.1h39.9h79.5h0.1H478V-6.3H406.9z
      M106,73.2l-3.4,2.6L102,79l-0.2,0.2l0.8-3.4l3.4-2.6l0.1-0.3L106,73.2z M176.7,79.5L176.7,79.5l0.2-3.1l0.2-0.1L176.7,79.5z
      M181.3,74.1L181.3,74.1l0.8-3.4l0,0L181.3,74.1z M188.4,75.6l-0.1-0.1l2.8-3.5l0.1,0L188.4,75.6z M206.4,74.9l-2.5,2.5l2.4-2.4
     l0.7-2.6L206.4,74.9z M208.7,69.7l0.2-0.2l0,0L208.7,69.7z M217.3,69.4L217.3,69.4l0.3-4.8l0.1,0L217.3,69.4z M225.7,59.8l1.1-0.8
     l0,0L225.7,59.8z M231.6,60.4l-0.2,0l3.2-4.5l0,0L231.6,60.4z M264.9,77l1.1-0.7l0,0L264.9,77z M278.7,74.5l-1.5,2.3l-0.2,0
     l1.3-2.1l3.3-4.4l0.3,0L278.7,74.5z M294.8,67.9l-1.6,1.6l-0.1,0l1.4-1.5l0.1-2.2l0.3-0.1L294.8,67.9z M310.4,71.1L310.4,71.1
     l0.9-1.7l0,0.1L310.4,71.1z M316.4,94.1L316.4,94.1l0.9-17.4l0,0L316.4,94.1z M326.8,129.5l0-0.1l0,0L326.8,129.5z M98.8,319.5
     l-4.2,3.2l-0.1,0l4.2-3.2l5.4-2.3l0,0L98.8,319.5z M121,308.2l-4.6,3.5l-4.6,5.6l-3.5,2.4l-3,0.8l0,0l2.9-0.8l3.5-2.4l4.6-5.6
     l4.6-3.5l2.4-0.1l0,0L121,308.2z M133.5,308.5l-3.5,4.1l-0.1,0l3.5-4.1l6.4-2.1l0,0L133.5,308.5z M153.2,303.7l-3.8,9.6l-0.1,0
     l3.8-9.6l1.9-2.4h0.1L153.2,303.7z M157.1,301.3H157l8.4-5.7l0.1,0L157.1,301.3z M195.4,330.3L195.4,330.3l9.5-11.1l0.1,0
     L195.4,330.3z M214.2,315.7l-3.1,3.9l-1.8,0.5l-0.1,0l1.8-0.5l3.1-3.9l2.5-1l0,0L214.2,315.7z M235.2,313.1v5.8l-6.6,2.9l-2.9,4
     l-3.4,1.6l0,0l3.3-1.6l2.9-4l6.6-2.9v-5.8l2.4-0.4l0,0L235.2,313.1z M89.7,636l-1.9,2.4l-3.2,2.1l-0.2-0.2l3.2-2l1.9-2.4h1l0,0.2
     L89.7,636z M113.4,628.2l-0.1-0.1l2.5-0.1L113.4,628.2z M121.3,619.9l1.8,3.8l-1.3,1.4l-4.3,2.6l-0.2,0l4.3-2.6l1.2-1.4l-1.7-3.9
     l3.7-3.6l0.2,0.1L121.3,619.9z M151.7,611.5l-5.3,5.5l-2,3.2l-0.2-0.1l1.9-3.1l5.3-5.5l2.2-0.3l0.2,0.1L151.7,611.5z M158.5,617.3
     l0-0.1l10-3.4l0.2,0L158.5,617.3z M191.1,586.6l0.7,3.3l-0.6,1.5l-5.1,5.1l-1.3,5.1l-2.6,4.8l-0.3,1.9l1.2,3L182,616l-2.5,0.5
     l-0.2,0l2.4-0.5l1-4.8l-1.2-3l0.3-1.9l2.5-4.7l1.4-5.1l5-5.1l0.6-1.5l-0.7-3.2l3.3-8.3l0.4,0L191.1,586.6z M213.1,567.6l-14.4,7.5
     l-0.5,3l-0.4,0l0.5-2.9l14.4-7.5l8.9-6.4l0.2,0.1L213.1,567.6z M232.2,562l-3.4,0.5l3-0.5l2.1-1.2l0.3,0.1L232.2,562z M414.7,193.7
     l2.9,6.7l-4.9,3.9l4.8-3.9L414.7,193.7z M399.1,206.4l3.5-2.3l0.1,0l-3.5,2.3l-0.6,1.2l1,8.8l-0.7,2l3.4,3.9l3.1,0.8l1.1,2.3l2,1.3
     l-2-1.3l-1.1-2.3l-3.1-0.8l-3.5-3.9l0.7-2l-1-8.8L399.1,206.4z M371.8,147.7L371.8,147.7l-0.8,8.8l4.2,10.8l1.5,9.4l-0.1,0
     l-1.5-9.4l-4.2-10.9L371.8,147.7z M293,369.8l3.4,0.2l15.6,4.2h9.6l7.4-4l5-7.3l6.1-3.7l2.5-0.2l2.5,1.5l2.8-0.5l2.1-2.2l0.8-2.2
     l-0.8,2.4l-2.2,2.2l-2.8,0.4l-2.4-1.5l-2.5,0.2l-6.2,3.8l-4.9,7.2l-7.4,4l-2.8,2.2l8.7,1.5l2.5-0.1l-2.7,0.2l-8.7-1.5l2.8-2.2h-9.6
     l-15.6-4.2l-3.4-0.2L293,369.8z M333.3,377.9L333.3,377.9l8.3,5.4l-1.4,5.9l1.9,10.6l4.8,6.3l-0.2,0l-5-6.5l-1.9-10.5l1.5-5.9
     L333.3,377.9z M252.3,323.4l0.5,1.5l-0.4,2l-0.2,0.2l0.4-2L252.3,323.4z M250,320.3L250,320.3l-4.9,3l0-0.1L250,320.3z
      M240.8,318.3l3.6-0.6l0,0L240.8,318.3L240.8,318.3z M286.4,372.8l-4.2-4.6l-3,0.3l-9.1-1.6l0.2-4.9l-3.5-23.1l-9.5-3l-2.8,0.7
     l-4,2.6l-9.2-0.5l-0.4-1l5.1-7.7l0.1,0l-5,7.5l0.4,1l9.2,0.5l4-2.6l2.8-0.7l9.5,3l3.5,23.1l-0.2,4.9l9.1,1.6l3-0.3l4.2,4.6l4.1-0.4
     l-0.2,0.2L286.4,372.8z M346.7,439.1l-0.5,19.4l-7.2,11.1l-3.1,16l-10.2,17.9l-0.1,0.1l-5.3,6l-1.4,6.4l0.2,3.7l-1,1.6l-4.1,3.2
     l-6.5,0.2l-5.2,1.3l-6.7,6.4l-7.5,14.5l2.4,0.5l-1.8,5.4l1.5,1.8l-2.1,3.2l1.9,5.7l-1.7,2.4l0.1,1.6l-5.3,6.8l1.9,6.1l-0.2,11.7
     l-0.2-0.1l0.3-11.7l-1.8-5.9l-14.1,2.8l-14.3,8.2l-8.7,6.1l-7.9,2l-0.2-0.1l7.7-2l8.6-6l14.4-8.3l14.1-2.8l5.3-6.8l-0.1-1.6
     l1.7-2.4l-1.9-5.7l2-3.2l-1.5-1.8l1.9-5.4l-2.4-0.5l7.5-14.5l6.7-6.4l5.2-1.3l6.5-0.3l4.1-3.1l1-1.6l-0.1-3.6l1.4-6.5l5.3-6
     l10.3-18.1l3.1-16l7.1-11.1l0.6-19.4l5.4-30.5l-2-1.7l0.3,0.1l2,1.7L346.7,439.1z M380.8,326.3l-12.1-5.6l-10.3,8.7l-7.3,12.8
     l-1.3,2.9l0-0.1l1.3-3l7.3-12.8l10.3-8.7l12.1,5.6l3.5,0.7L380.8,326.3z M396.7,243l-5.1,3L396.7,243L396.7,243z M397,239.7
     L397,239.7l3.5-4.8l1-1l4.9-1v-1.7h1.3l1.9-3.8l0,0l-1.9,3.9h-1.3v1.7l-4.9,1l-1,1L397,239.7z M404.7,303.6L401,305l-0.4,0.3
     l0.5-0.4L404.7,303.6L404.7,303.6z M405.3,287.8L405.3,287.8l4.4-6.5l11.3,9.4l-11.3-9.3L405.3,287.8z"
        />
        <polygon
          style={{ fill: fill }}
          points="140.2,3.7 143.6,0.4 145.7,1.9 145.4,3.9 146.3,5.1 147.9,8.6 144.6,11 143.1,12.3 143.1,13.4 147.7,14.7 
     153,13.7 153.9,12 156.7,7.4 158.7,3.7 162.1,2.5 171.8,-6.3 142.7,-6.3 143.1,-4.1 138.4,-4.1 137.5,-2.2 139.1,1.9 	"
        />
        <polygon
          style={{ fill: fill }}
          points="124.6,-2.1 127.9,-2.1 133.3,-2.7 133.7,-4.5 133.9,-6.3 119,-6.3 117.5,-4.1 123,-1.3 	"
        />
        <polygon
          style={{ fill: fill }}
          points="112.4,9.8 113.6,12.3 110.3,12.4 110.9,15.5 115.4,18.3 119.9,18.9 116.8,20.7 112.4,18.4 110.7,19.3 110.5,20.4 
     114.6,24.8 115.8,26.8 117.3,28.8 119.5,29.2 133.5,25.3 137.2,25 142,19.3 141.6,16.3 139.3,16.3 138.7,14.1 141,12 143.5,8.7 
     143.8,7.3 143.6,5.3 142.1,5.2 139.7,4.7 137.5,5.1 133.5,6.6 134,2.4 134.3,1.1 130.9,1.1 130.4,3.7 127.7,1.9 123.7,2.1 
     119.9,3.3 114.8,3.9 112.6,6.1 	"
        />
        <polygon
          style={{ fill: fill }}
          points="102.1,17.3 104.2,17.1 106.7,18.5 106.3,15.3 105.9,13.9 105.3,11.1 100,10.1 97.7,10.7 95,12.8 97,16.3 	"
        />
        <polygon
          style={{ fill: fill }}
          points="104.9,-1.5 105.8,-1.3 112.3,-4.9 114,-6.3 105.4,-6.3 104.1,-4.7 105.2,-3.3 	"
        />
        <polygon
          style={{ fill: fill }}
          points="86,681.6 84.9,680 86.9,677.3 87.5,676.4 86.9,673.5 83.5,671.7 82.7,670.9 82.7,669.3 84.8,666.9 84.3,662.4 
     81.6,657.7 77.6,654.1 76.4,656.8 75.6,659.8 77.9,663.9 75.2,664.9 72.5,666.6 72.3,669.4 74,673.9 75.2,675.7 75.2,677.4 
     74.8,681.7 72.6,681.8 71.1,686.6 71.8,689.3 71.9,691.2 70.3,694.2 70.5,697.8 70.3,699.8 71,702.6 71.2,704.4 72.9,707.1 
     73.1,710.7 74.3,712 78.9,712.6 84.1,712.6 84.9,711.4 86.2,707.6 86.6,700.6 87.7,694.4 87.9,684.6 87.2,682.3 	"
        />
        <polygon
          style={{ fill: fill }}
          points="102.7,695 97.8,690.5 94.6,689.8 94,691.7 93.3,693.8 93.3,696 94,701.2 93.1,702.6 93.3,704.9 95,708.6 
     96.4,708.4 96.6,705.8 97.6,704.4 99.3,707 100.4,708.6 101.5,708.5 102.4,704.4 104.5,702.3 104,699.7 	"
        />
        <polygon
          style={{ fill: fill }}
          points="126.3,724.1 122,721.4 118.7,719.7 117.2,719.4 114.4,720.5 113.9,722 115.8,724.9 117.2,724.7 119.4,725.8 
     122.8,727.9 125.9,729.5 128.8,730.5 129.8,729.2 129,727 	"
        />
        <polygon
          style={{ fill: fill }}
          points="158.7,694.6 162,692.8 168,691.8 172.6,689.4 174.5,687.2 172.5,682.7 168.1,679.3 162.7,680.5 159.5,680.1 
     156.4,681.7 154,683.5 153.5,687.3 154.6,691.7 156.1,693.7 	"
        />
        <polygon
          style={{ fill: fill }}
          points="141.8,700.6 141.2,703.2 140.2,703.7 137.8,704.8 136.9,705.6 137,709.6 138,712 139.8,713.2 143,713.2 
     144.7,712.5 145.7,709.3 148.6,707.1 148.6,703.1 143.5,699.7 	"
        />
        <polygon
          style={{ fill: fill }}
          points="136.9,717.6 133.6,715.7 127.3,716.3 123.1,715.1 121.2,715.2 119,714.3 118,712.3 116.1,714 114.9,715.2 
     114.2,716.6 115.7,718.1 118.3,716.6 120.7,718.9 125.9,721.9 129.3,723.8 132.9,727.5 135.4,728.3 138.1,725.8 137.8,719.5 	"
        />
        <polygon
          style={{ fill: fill }}
          points="478,-26 478,-6.3 478,730.9 478,753 933,753 933,-26 	"
        />
      </g>
      <g>
        <Tooltip title="Sogndal" placement="top">
   
            <circle
              style={{
                fill: fill2,
                stroke: hovered === "Sogndal" ? hoverColor : "transparent",
              }}
              stroke="black"
              strokeWidth="5"
              cx="245.5"
              cy="244.5"
              r="20"
              onMouseEnter={() => onHover("Sogndal")}
              onMouseLeave={() => onHover("")}
              onClick={() => onClick("Sogndal")}
            />
    
        </Tooltip>
        <Tooltip title="Førde" placement="top">
     
            <circle
              style={{
                fill: fill2,
                stroke: hovered === "Førde" ? hoverColor : "transparent",
              }}
              stroke="black"
              strokeWidth="5"
              cx="144.5"
              cy="198.7"
              r="20"
              onMouseEnter={() => onHover("Førde")}
              onMouseLeave={() => onHover("")}
              onClick={() => onClick("Forde")}
            />
  
        </Tooltip>
        <Tooltip title="Bergen" placement="top">
 
            <circle
              style={{
                fill: fill2,
                stroke: hovered === "Bergen" ? hoverColor : "transparent",
              }}
              stroke="black"
              strokeWidth="5"
              cx="89.4"
              cy="438.8"
              r="20"
              onMouseEnter={() => onHover("Bergen")}
              onMouseLeave={() => onHover("")}
              onClick={() => onClick("Bergen")}
            />
  
        </Tooltip>
        <Tooltip title="Stord" placement="top">

            <ellipse
              style={{
                fill: fill2,
                stroke: hovered === "Stord" ? hoverColor : "transparent",
              }}
              stroke="black"
              strokeWidth="5"
              transform="matrix(3.327796e-02 -0.9994 0.9994 3.327796e-02 -466.8854 638.9176)"
              cx="96.8"
              cy="560.8"
              rx="20"
              ry="20"
              onMouseEnter={() => onHover("Stord")}
              onMouseLeave={() => onHover("")}
              onClick={() => onClick("Stord")}
            />
         
        </Tooltip>
        <Tooltip title="Haugesund" placement="top">
         
            <ellipse
              style={{
                fill: fill2,
                stroke: hovered === "Haugesund" ? hoverColor : "transparent",
              }}
              stroke="black"
              strokeWidth="5"
              transform="matrix(3.327796e-02 -0.9994 0.9994 3.327796e-02 -558.1682 703.8521)"
              cx="84.8"
              cy="640.5"
              rx="20"
              ry="20"
              onMouseEnter={() => onHover("Haugesund")}
              onMouseLeave={() => onHover("")}
              onClick={() => onClick("Haugesund")}
            />
       
        </Tooltip>
      </g>
    </svg>
  )
}
